import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import api from "../../services/api";
import { openPrivacyNTerms } from "../../helpers";
import { Flex } from "../../helpers/styles";
import { Text, Underscore, IconImage } from "./index";
import QuestionContainer from "./QuestionContainer";
import WhatsappIcon from "../../assets/images/whatsapp.svg";
import Cedro from "../../assets/images/cedro.png";
import InstitutoC from "../../assets/images/logo_instituto_c.png";
import Alquimia from "../../assets/images/alquimia.png";
import Prosaber from "../../assets/images/pro-saber.JPG";
import PequenoCidadao from "../../assets/images/logo_casa_do_pequeno_cidadao.png";
import Unibes from "../../assets/images/unibes.png";
import CustomTable from "../../components/CustomTable";
import ButtonLink from "../../components/ButtonLink";

const HowToSell = () => {
    const [pickupPoints, setPickupPoints] = useState([]);
    const getPickupPoints = async () => {
        try {
            const response = await api.get("/pickup_points");
            setPickupPoints(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPickupPoints();
    }, []);
    return (
        <>
            <QuestionContainer title="1. Como faço o meu cadastro para vender?">
                <Text>
                    Cadastrar-se é o primeiro passo para vender suas peças na Re Petit. Veja como é fácil:
                    <ol>
                        <li>
                            Clique em <strong>QUERO VENDER</strong> no menu superior.
                        </li>
                        <li>
                            Insira seus dados cadastrais e nos conte um pouco mais sobre o que vai desapegar, clique em{" "}
                            <strong>FINALIZAR</strong> e pronto.
                        </li>
                    </ol>
                    <strong>ATENÇÃO: Não recebemos peças sem o cadastro pelo site.</strong>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="2. Quais peças posso vender?">
                <Text>
                    Roupas, acessórios, calçados, livros e brinquedos em excelente estado e que ainda têm muita história
                    para contar! Utensílios na embalagem como copos e chupetas também são bem-vindos. Ah e você também
                    pode desapegar o carrinho de bebê, cadeirinhas, entre outros. Confira abaixo a lista completa!
                    <br />
                    <br />
                    <strong>
                        <Underscore>Crianças que vestem de 0 até 14 anos</Underscore>
                    </strong>
                    <br />
                    <strong>Roupas</strong>
                    <ul>
                        <li>Bata</li>
                        <li>Biquíni</li>
                        <li>Body</li>
                        <li>Calça</li>
                        <li>Calcinha (nova – com etiqueta)</li>
                        <li>Camisa</li>
                        <li>Camiseta</li>
                        <li>Casaco</li>
                        <li>Colete</li>
                        <li>Conjunto</li>
                        <li>Cueca (nova – com etiqueta)</li>
                        <li>Fantasia</li>
                        <li>Fralda Ecológica</li>
                        <li>Jardineira</li>
                        <li>Jaqueta</li>
                        <li>Macacão</li>
                        <li>Maiô</li>
                        <li>Meias (novas – com etiqueta)</li>
                        <li>Mijão</li>
                        <li>Pijama</li>
                        <li>Poncho</li>
                        <li>Romper</li>
                        <li>Roupão</li>
                        <li>Saia</li>
                        <li>Saída de praia</li>
                        <li>Shorts</li>
                        <li>Sunga</li>
                        <li>Terno</li>
                        <li>Vestido</li>
                    </ul>
                    <strong>Acessórios</strong>
                    <ul>
                        <li>Acessórios de cabelo</li>
                        <li>Babador</li>
                        <li>Bolsa</li>
                        <li>Boné</li>
                        <li>Cachecol</li>
                        <li>Canguru</li>
                        <li>Capa de Chuva</li>
                        <li>Cinto</li>
                        <li>Cobertor</li>
                        <li>Decoração</li>
                        <li>Escova de cabelo</li>
                        <li>Gorro</li>
                        <li>Joelheira</li>
                        <li>Joia</li>
                        <li>Luva</li>
                        <li>Manta</li>
                        <li>Mochila</li>
                        <li>Naninha</li>
                        <li>Óculos</li>
                        <li>Pano de boca</li>
                        <li>Pano de ombro</li>
                        <li>Protetor</li>
                        <li>Protetor de cotovelos</li>
                        <li>Protetor de joelhos</li>
                        <li>Pulseira</li>
                        <li>Roupa de cama</li>
                        <li>Swaddler</li>
                        <li>Toalhas</li>
                    </ul>
                    <strong>Calçados do nº 20 até o nº 35</strong>
                    <ul>
                        <li>Alpargata</li>
                        <li>Botas</li>
                        <li>Chinelo</li>
                        <li>Chuteira</li>
                        <li>Mocassim</li>
                        <li>Pantufa</li>
                        <li>Sandália</li>
                        <li>Sapatilha</li>
                        <li>Sapatinho</li>
                        <li>Sapato</li>
                        <li>Tamanco</li>
                        <li>Tênis</li>
                    </ul>
                    <strong>Objetos e utensílios:</strong>
                    <ul>
                        <li>Almofada de amamentação</li>
                        <li>Almofada para banho</li>
                        <li>Amassador de papinha</li>
                        <li>Aquecedor de mamadeira</li>
                        <li>Aspirador nasal (na embalagem)</li>
                        <li>Assento redutor de privada</li>
                        <li>Babá eletrônica</li>
                        <li>Banheira</li>
                        <li>Bebê conforto</li>
                        <li>Berço portátil</li>
                        <li>Bico de mamadeira (na embalagem)</li>
                        <li>Boia</li>
                        <li>Bolsa maternidade</li>
                        <li>Bolsa organizadora para carrinho</li>
                        <li>Bolsa térmica</li>
                        <li>Bomba extratora de leite</li>
                        <li>Cadeira para alimentação</li>
                        <li>Cadeira para bicicleta</li>
                        <li>Canguru</li>
                        <li>Capa de chuva para carrinho</li>
                        <li>Capa para bebê conforto</li>
                        <li>Capa para carrinho</li>
                        <li>Capa para moisés</li>
                        <li>Carrinho</li>
                        <li>Cercado</li>
                        <li>Chupeta (na embalagem)</li>
                        <li>Copo</li>
                        <li>Escorredor de mamadeira</li>
                        <li>Espelho para carro</li>
                        <li>Estação de papinha</li>
                        <li>Esterilizador de mamadeira</li>
                        <li>Extrator de leite</li>
                        <li>Gancho para carrinho</li>
                        <li>Garrafa</li>
                        <li>Grade protetora</li>
                        <li>Guarda-chuva para carrinho</li>
                        <li>Kit unhas</li>
                        <li>Lancheira</li>
                        <li>Mala de maternidade</li>
                        <li>Mamadeira ( na embalagem )</li>
                        <li>Máquina de fazer papinha</li>
                        <li>Móbile</li>
                        <li>Móbile para berço</li>
                        <li>Móbile para carrinho</li>
                        <li>Mochila</li>
                        <li>Moisés</li>
                        <li>Monitor de movimento</li>
                        <li>Organizador de carrinho</li>
                        <li>Porta-chupeta</li>
                        <li>Porta copo para carrinho</li>
                        <li>Porta lenço umedecido</li>
                        <li>Porta retrato</li>
                        <li>Porta treco</li>
                        <li>Pote</li>
                        <li>Prato</li>
                        <li>Prendedor de chupeta</li>
                        <li>Protetor de assento</li>
                        <li>Protetor de berço</li>
                        <li>Rede</li>
                        <li>Redutor de assento</li>
                        <li>Rolo protetor</li>
                        <li>Saco de dormir</li>
                        <li>Saco esterilizador</li>
                        <li>Skate para carrinho</li>
                        <li>Sling</li>
                        <li>Suporte para Moisés</li>
                        <li>Tapa fraldas</li>
                        <li>Tapete</li>
                        <li>Trocador</li>
                        <li>White noise</li>
                    </ul>
                    <strong>Brinquedos:</strong>
                    <ul>
                        <li>Almofada de atividades</li>
                        <li>Andador</li>
                        <li>Bicicleta</li>
                        <li>Brinquedos de bebê</li>
                        <li>Brinquedos infantis</li>
                        <li>Brinquedos educativos</li>
                        <li>Brinquedos de madeira</li>
                        <li>Brinquedos musicais</li>
                        <li>Patinete</li>
                        <li>Tapete de atividades</li>
                        <li>Livros</li>
                    </ul>
                    <p>
                        <strong>IMPORTANTE:</strong> Roupas, calçados, brinquedos, livros e acessórios ficam com a Re
                        Petit por um período de 12 meses. Os objetos grandes, como carrinhos e berços portáteis, ficam
                        com a Re Petit pelo período de 6 meses.
                    </p>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="3. Quais marcas são aceitas?">
                <Text>
                    A Re Petit trabalha com marcas originais que prezam pelo estilo, qualidade e durabilidade. Fique de
                    olho se a peça que separou é uma delas! Confira aqui todas as{" "}
                    <RouterLink to="/o-que-vender#marcas">MARCAS ACEITAS</RouterLink>.
                </Text>
            </QuestionContainer>

            <QuestionContainer title="4. Como envio as minhas peças?">
                <Text>
                    <strong>Prepare-se para enviar suas peças: </strong>
                    <ol>
                        <li>
                            Cadastre-se <strong>no site. Sem o cadastro não aceitamos as peças.</strong>
                        </li>
                        <li>
                            Selecione o que quer desapegar e <strong>identifique todas</strong>
                            as suas sacolas e caixas{" "}
                            <strong>com seu nome completo( idêntico ao fornecido no cadastro).</strong>
                        </li>
                    </ol>
                    <strong>Confira as formas de enviar suas peças para a seleção:</strong>
                    <ol>
                        <li>
                            <strong>Retirada na sua casa.</strong> Se estiver na cidade de São Paulo, nós retiramos tudo
                            na sua casa. Preencha o <RouterLink to="/quero-vender">CADASTRO</RouterLink> Entraremos em
                            contato para passar os valores e agendar a retirada. .
                        </li>
                        <li>
                            <strong>Pontos de Entrega. Se preferir entregar</strong> você temos 9 pontos em São Paulo e
                            2 pontos de Campinas:
                            <br />
                            <br />
                            <ul>
                                {pickupPoints.map((item) => {
                                    return (
                                        <li>
                                            {item.name +
                                                " - " +
                                                item.address.street +
                                                ", " +
                                                item.address.number +
                                                " - " +
                                                item.address.neighborhood +
                                                ", " +
                                                item.address.city +
                                                "/" +
                                                item.address.state}
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                        <br />
                        <li>
                            <strong>Devolução do BOX.</strong> Ao pedir o BOX você recebe também uma REPETIT BAG
                            (sacola-envelope) junto com as peças. É só colocar as peças que deseja vender nesta sacola,
                            e devolver na hora da retirada.{" "}
                            <strong>
                                Lembre-se de preencher o cadastro{" "}
                                <RouterLink to="/quero-vender">QUERO VENDER</RouterLink> antes da devolução do Box e
                                envio das RE PETIT BAG.
                            </strong>
                            <br />{" "}
                            <li>
                                <strong>Envio pelos correios:</strong> Você pode mandar suas peças pelo correio. O
                                endereço para envio é Rua Andréa Paulinetti, 53 - CEP 04707-050, Brooklin, São Paulo/SP.{" "}
                                <strong>
                                    Lembre-se de preencher o cadastro{" "}
                                    <RouterLink to="/quero-vender">QUERO VENDER</RouterLink> antes do envio.
                                </strong>
                            </li>
                        </li>
                    </ol>
                    <strong>Nossas regras: </strong>
                    <br />
                    <ul>
                        <li>O mínimo para aceitação são 20 peças.</li>
                        <li>
                            As peças devem estar dentro da{" "}
                            <RouterLink to="/o-que-vender#produtos">lista de marcas que trabalhamos.</RouterLink>
                        </li>
                        <li>
                            NÃO recebemos peças para a venda de pessoas que não estejam{" "}
                            <strong>previamente cadastradas</strong> no site, bem como sacolas que não estejam{" "}
                            <strong>identificadas com o nome idêntico ao do cadastro.</strong>
                        </li>
                    </ul>
                    <br />
                    <strong>AINDA TEM DÚVIDAS?</strong>
                    <br />
                    Entre em contato com nossa equipe pelo email:{" "}
                    <a href="mailto:equipe@repetit.com.br">equipe@repetit.com.br</a>
                </Text>
            </QuestionContainer>
            <QuestionContainer title="5. O QUE É A REPETIT BAG?">
                <Text>
                    <p>
                        <strong>A</strong> Re Petit Bag é uma maneira rápida e prática desapegar com a gente. Uma sacola
                        envelope que você encontra nossos pontos parceiros e pode encher de coisas para circular.{" "}
                    </p>
                    <p>
                        Leve a sua para casa e faça a seleção do que quer desapegar. Preencha o{" "}
                        <RouterLink to="/quero-vender">QUERO VENDER</RouterLink> e pronto! Retiramos na sua casa,
                        consulte valor da sua região (somente cidade de SP) ou se preferir leve sua REPETIT BAG com seus
                        desapegos para um dos pontos de coleta!{" "}
                    </p>
                    <p>
                        Não é só o planeta que ganha com essa, você também! Entregando sua RE PETIT BAG em um dos nossos
                        parceiros você pode ganhar de 5% até 10% desconto para usar na hora.{" "}
                    </p>
                    <p>Confira onde você pode retirar a sua Re Petit Bag:</p>
                    <br />
                    <strong>São Paulo:</strong>
                    <ul>
                        {pickupPoints.map((item) => {
                            return (
                                <li>
                                    {item.name +
                                        " - " +
                                        item.address.street +
                                        ", " +
                                        item.address.number +
                                        " - " +
                                        item.address.neighborhood +
                                        ", " +
                                        item.address.city +
                                        "/" +
                                        item.address.state}
                                </li>
                            );
                        })}
                    </ul>
                    <br />
                    <strong>Campinas:</strong>
                    <ul>
                        <li>
                            Arte Sob Medida - Shopping Iguatemi Campinas - 3 Piso
                            <br />
                            De segunda a domingo nos horários de funcionamento do Shopping.
                        </li>
                        <li>
                            Arte Sob Medida - Shopping Galleria Campinas - Alameda de Serviços
                            <br />
                            De segunda a domingo nos horários de funcionamento do Shopping.
                        </li>
                    </ul>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="6. Qual o critério de avaliação das peças?">
                <Text>
                    A Re Petit é expert na curadoria de peças infantis e faz uma triagem criteriosa de todos os itens.
                    Confira as regras!
                    <br />
                    <br />
                    <strong>Roupas, acessórios e calçados:</strong>
                    <ol>
                        <li>Só aceitamos roupas de 0 até 14 anos e calçados do 20 ao número 35.</li>
                        <li>Peças sempre bem conservadas e originais.</li>
                        <li>
                            Peças manchadas, amareladas, desfiadas, furadas, rasgadas ou com qualquer tipo de avaria não
                            entram na seleção.
                        </li>
                        <li>
                            Peças de tecido animal ou em couro sintético, bem como roupa íntima e meias já usadas, não
                            são aceitas.
                        </li>
                        <li>Calçados somente bem conservados e com a sola limpa.</li>
                        <li>Calcinhas, cuecas e meias somente novas com etiqueta.</li>
                    </ol>
                    <strong>
                        <Underscore>Olho na limpeza:</Underscore> Todas as peças enviadas já devem estar lavadas e
                        limpas. Isso aumenta as chances de aceitação!
                    </strong>
                    <br />
                    <br />
                    <strong>Brinquedos, objetos e utensílios:</strong>
                    <br />
                    <br />
                    Todos os itens devem ser de marcas originais, estar em perfeito estado e limpos.
                    <ul>
                        <li>
                            <strong>Brinquedos</strong>
                            <br />
                            Aceitamos apenas brinquedos com todas as peças funcionando e das marcas listadas{" "}
                            <RouterLink to="/o-que-vender#marcas">aqui</RouterLink>.
                        </li>
                        <li>
                            <strong>Utensílios</strong>
                            <br />
                            Chupetas, mamadeiras e utensílios de boca para bebê somente novos e na embalagem de origem.
                        </li>
                        <li>
                            <strong>Objetos</strong>
                            <br />
                            Carrinhos e cadeirinhas de carro só são aceitos com o manual acompanhando e com data de
                            fabricação comprovada de até 3 anos.
                        </li>
                    </ul>
                    <strong>Fique de olho:</strong> <br />
                    Após suas peças chegarem aqui, a Re Petit tem até <strong>20 dias úteis</strong> para avaliar
                    criteriosamente cada uma delas, e dar um retorno a você.
                    <br />
                    <br />
                    <strong>AINDA TEM DÚVIDAS?</strong> <br /> Envie sua mensagem para{" "}
                    <a href="mailto:equipe@repetit.com.br">equipe@repetit.com.br</a> ou pelo WhatsApp{" "}
                    <a href="https://api.whatsapp.com/send?phone=5511976922404">(11) 97692-2404</a>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="7. O que acontece com as peças selecionadas?">
                <Text>
                    Tudo o que passa na seleção da Re Petit tem um tratamento especial e todo esse trabalho é por nossa
                    conta:
                    <br />
                    <ol>
                        <li>Fotografamos.</li>
                        <li>Armazenamos, cuidamos e anunciamos suas peças com carinho.</li>
                        <li>Vendemos suas peças tanto no site quanto no BOX.</li>
                        <li>
                            Você resgata seu crédito da maneira que quiser, ou transferência para sua conta bancária ou
                            em créditos para compras na Re Petit.
                        </li>
                    </ol>
                    <strong>Fique de olho:</strong>
                    <ol>
                        <li>
                            Após você aprovar o valor de venda das peças, a Re Petit tem{" "}
                            <strong>até 10 dias úteis</strong> para produzir as fotos e anunciá-las no site.
                        </li>
                        <li>Os crédito são liberados em até 30 dias após a confirmação da venda.</li>
                    </ol>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="8. O que acontece com as peças não aceitas?">
                <Text>
                    Ao receber a proposta de venda você será notificado(a) sobre suas peças que não passaram por nosso
                    critério de avaliação. Basta acessar o seu perfil em <strong>MINHA CONTA</strong> e depois em{" "}
                    <strong>MEUS PRODUTOS</strong> e escolher o que prefere:
                    <ol>
                        <li>
                            <strong>Retirar sua peça.</strong> Você tem até 10 dias corridos para solicitar suas peças
                            após o envio da notificação. Agende a retirada com nossa equipe pelo email:{" "}
                            <a href="mailto:equipe@repetit.com.br">equipe@repetit.com.br</a> ou pelo WhatsApp{" "}
                            <a href="https://api.whatsapp.com/send?phone=5511976922404">(11) 97692-2404</a>
                        </li>
                        <li>
                            <strong>Doar sua peça.</strong> A cada ano escolhemos uma nova instituição para encaminhar
                            as doações. É só dar o seu OK que entregamos suas peças para nossa ONG parceira.
                        </li>
                    </ol>
                    <strong>Confira as ONGs já beneficiadas:</strong>
                    <br />
                    <Flex justifyContent="flex-center">
                        <Flex direction="column" margin="16px 32px">
                            <img src={Unibes} height="125px" alt="Unibes" />
                            Unibes
                            <a href="https://unibes.org.br/">https://unibes.org.br/</a>
                            <span>2019</span>
                        </Flex>
                        <Flex direction="column">
                            <img src={Cedro} height="125px" alt="Cedro do Líbano" />
                            Cedro do Líbano
                            <a href="https://www.cedrodolibano.org.br/">https://www.cedrodolibano.org.br/</a>
                            <span>2020</span>
                        </Flex>
                        <Flex direction="column">
                            <img src={Alquimia} height="125px" alt="Cedro do Líbano" />
                            Alquimia
                            <a href="http://alquimiaong.org.br/">http://alquimiaong.org.br/</a>
                            <span>2021</span>
                        </Flex>
                        <Flex direction="column">
                            <img src={Prosaber} height="125px" alt="Pró-Saber" />
                            Pró-Saber
                            <a href="https://prosabersp.org.br/">https://prosabersp.org.br/</a>
                            <span>2022</span>
                        </Flex>
                        <Flex direction="column">
                            <img src={InstitutoC} height="125px" alt="Instituto C" />
                            Instituto C<a href="https://institutoc.org.br/">https://institutoc.org.br/</a>
                            <span>2023</span>
                        </Flex>
                        <Flex direction="column">
                            <img src={PequenoCidadao} height="125px" alt="Casa do Pequeno Cidadão" />
                            Casa do Pequeno Cidadão
                            <a href="https://www.casadopequenocidadao.org.br/">
                                https://www.casadopequenocidadao.org.br/
                            </a>
                            <span>2024</span>
                        </Flex>
                    </Flex>
                    <br />
                    <strong>Fique de olho!</strong> As peças ficam com a Re Petit por 10 dias corridos após o envio da
                    notificação. Caso você não entre em contato neste prazo para retirar as peças não aceitas, elas
                    serão doadas para a nossa instituição parceira automaticamente.
                </Text>
            </QuestionContainer>

            <QuestionContainer title="9. Como os preços são definidos?">
                <Text>
                    A Re Petit já é <strong>expert na curadoria de peças infantis</strong> e conta com{" "}
                    <strong>critérios de triagem</strong> bem definidos como{" "}
                    <strong>marcas aceitas, estilo e o estado de conservação das peças.</strong>
                    <br />
                    <br />
                    Todas essas informações passam por nosso <strong>sistema inteligente</strong>, que acessa nosso
                    banco de dados com o histórico das vendas realizadas até hoje, e nos dá uma referência dos valores.
                    Tudo isso conta para a definição final do valor das peças.
                    <br />
                    <br />
                    <strong>Tabela de Comissão</strong>
                    <CustomTable
                        columns={[
                            { label: "Valor da venda", name: "valor" },
                            { label: "% RE PETIT", name: "repetit" },
                            { label: "% VENDEDORA", name: "vendedora" },
                        ]}
                        rows={[
                            {
                                valor: "Até R$ 40,00",
                                repetit: "80%",
                                vendedora: "20%",
                            },
                            {
                                valor: "De R$ 40,01 até R$ 80,00",
                                repetit: "75%",
                                vendedora: "25%",
                            },
                            {
                                valor: "De R$ 80,01 até R$ 100,00",
                                repetit: "70%",
                                vendedora: "30%",
                            },
                            {
                                valor: "De R$ 100,01 até R$ 150,00",
                                repetit: "65%",
                                vendedora: "35%",
                            },
                            {
                                valor: "De R$ 150,01 até R$ 300,00",
                                repetit: "60%",
                                vendedora: "40%",
                            },
                            {
                                valor: "De R$ 300,01 até R$ 500,00",
                                repetit: "55%",
                                vendedora: "45%",
                            },
                            {
                                valor: "De R$ 500,01 até R$ 800,00",
                                repetit: "50%",
                                vendedora: "50%",
                            },
                            {
                                valor: "De R$ 800,01 até R$ 1.000,00",
                                repetit: "45%",
                                vendedora: "55%",
                            },
                            {
                                valor: "Acima de R$ 1.000,01",
                                repetit: "35%",
                                vendedora: "65%",
                            },
                        ]}
                    />
                    <br />
                    O pagamento é disponibilizado em 30 dias após a confirmação da venda no site. Você recebe o valor em
                    sua conta em até 3 dias úteis.
                    <br />
                    <br />
                    Para vender com a Re Petit é obrigatório ter uma conta bancária (corrente ou poupança) no mesmo nome
                    e CPF do cadastro. Por questões de segurança, não é possível alterar o CPF cadastrado na conta da Re
                    Petit. Caso você queira usar a conta bancária de um parente ou amigo de confiança, você deve
                    preencher os dados de seu perfil na Re Petit com o CPF do titular da conta bancária. Caso haja erro
                    de cadastro a transferência não poderá ser efetuada.
                    <br />
                    <br />
                    <strong>Quer ter uma prévia das suas vendas?</strong> Faça uma simulação e descubra o quanto pode
                    valer sua peça em nossa <RouterLink to="/desapegue">CALCULADORA DE PREÇO</RouterLink>
                    .
                    <br />
                    <br />
                    <strong>Fique de Olho!</strong> Os valores apresentados na tabela e na{" "}
                    <strong>Calculadora de Preço</strong> são <strong>estimados</strong> e podem sofrer alterações após
                    a avaliação da peça.
                    <br />
                    <br />
                    <strong>AINDA TEM DÚVIDAS?</strong>
                    <br />
                    Entre em contato com nossa equipe pelo email:{" "}
                    <a href="mailto:equipe@repetit.com.br">equipe@repetit.com.br</a> ou pelo WhatsApp{" "}
                    <a href="https://api.whatsapp.com/send?phone=5511976922404">(11) 97692-2404</a>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="10. Não concordo com o valor de venda. Podemos negociar?">
                <Text>
                    Com certeza! O valor apresentado é baseado em nossa experiência em curadoria de peças infantis. Mas
                    se ainda assim você discordar do valor proposto nós podemos analisar o seu caso. Fale com nossa
                    equipe pelo email: <a href="mailto:equipe@repetit.com.br">equipe@repetit.com.br</a> ou pelo WhatsApp{" "}
                    <a href="https://api.whatsapp.com/send?phone=5511976922404">(11) 97692-2404</a>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="11. Não aceitei vender minhas peças após a avaliação e negociação. O que devo fazer?">
                <Text>
                    É uma pena que não deu certo. Nós analisamos, selecionamos e cuidamos das peças com atenção e
                    carinho. Por este trabalho cobramos uma taxa para liberar a devolução das peças:
                    <br />
                    <br />
                    <ul>
                        <li>até 30 peças: R$ 25,00</li>
                        <li>de 31 até 100: R$ 50,00</li>
                        <li>de 101 até 200: R$ 100,00</li>
                        <li>201 até 300: R$ 150,00</li>
                        <li>Após 301: 150,00 + 25,00 a cada 30.</li>
                    </ul>
                    Após o pagamento você tem até 10 dias corridos para a retirar sua peça na Re Petit. Importante que
                    você agende a retirada com nossa equipe pelo menos com um dia de antecedência e a confirmação de
                    pagamento da taxa. Para dúvidas e agendamentos entre em contato pelo e-mail:{" "}
                    <a href="mailto:equipe@repetit.com.br">equipe@repetit.com.br</a> ou pelo WhatsApp{" "}
                    <a href="https://api.whatsapp.com/send?phone=5511976922404">(11) 97692-2404</a>
                    <br />
                    <br />
                    <strong>QUER SABER MAIS?</strong>
                    <br />
                    <strong>
                        Confira nossos{" "}
                        <ButtonLink onClick={() => openPrivacyNTerms("terms")}>Termos e Condições</ButtonLink>
                    </strong>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="12. O que acontece após a venda da minha peça?">
                <Text>
                    <ol>
                        <li>
                            Acesse a sua conta na Re Petit para acompanhar o status das peças em{" "}
                            <strong>MEUS PRODUTOS</strong> e o valor atual já liberado em <strong>MEUS CRÉDITOS</strong>
                            .
                        </li>
                        O seu saldo é liberado no site em{" "}
                        <strong>até 30 dias corridos após a confirmação da venda!</strong>
                        <strong>E o melhor: você pode usar seu saldo como quiser:</strong>
                        Usar seus saldo para novas compras ou sacar e receber em sua conta bancária*
                        <br />
                        <br />
                        <li>
                            <strong>Créditos no site da Re Petit.</strong> Troque seu saldo por peças avulsas no site ou
                            um BOX. Espie só o que você pode resgatar em <RouterLink to="/produtos">COMPRE</RouterLink>.
                            <br />
                            <br />
                            *O dinheiro entra na sua conta bancária em até <strong>3 dias úteis</strong>.
                            <br />
                            **A conta bancária e cpf devem ser o mesmo do cadastro no site.
                        </li>
                    </ol>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="13. O que posso fazer para aumentar minhas chances de venda?">
                <Text>
                    Suas peças ficam com a Re Petit por até 12 meses, oferecemos um plano de descontos progressivos para
                    estimular as vendas.
                    <ul>
                        <li>Após 6 meses = 10% de desconto - opcional</li>
                        <li>Após 10 meses = 25% de desconto - aplicado automaticamente</li>
                    </ul>
                    Você receberá notificações sobre as promoções e a qualquer momento pode colocar uma peça em promoção
                    clicando em <strong>ATIVAR PROMOÇÂO.</strong>
                    <br />
                    <br />
                    <strong>Fique de olho!</strong>
                    <br />
                    Caso tenha alguma dúvida entre em contato com a nossa equipe pelo email:{" "}
                    <a href="mailto:equipe@repetit.com.br">equipe@repetit.com.br</a> ou pelo WhatsApp{" "}
                    <a href="https://api.whatsapp.com/send?phone=5511976922404">(11) 97692-2404</a> .
                </Text>
            </QuestionContainer>

            <QuestionContainer title="14. O que significam os dados na minha área de login?">
                <Text>
                    Esse é o resumo da sua conta, assim fica fácil visualizar sua movimentação. <br />
                    Saldo futuro = saldo aguardando liberação, para ter certeza que a peça não foi devolvida, prazo de
                    14 dias.
                    <br />
                    Saldo liberado para resgate = saldo que já pode ser utilizado em compras ou sacado.
                    <br />
                    Vendido = valor total de vendas já aprovadas até o momento.
                    <br />À venda = saldo das suas peças ainda à venda.
                </Text>
            </QuestionContainer>

            <QuestionContainer title="15. Como acompanho as minhas vendas?">
                <Text>
                    É só acessar a sua conta em <strong>MEUS CRÉDITOS</strong> acompanhar seu extrato. Se quiser
                    conferir as peças acesse <strong>MEUS PRODUTOS.</strong>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="16. Quero minha peça de volta. O que devo fazer?">
                <Text>
                    Durante qualquer momento você pode pedir uma ou mais peças de volta, só entrar em contato pelo
                    e-mail <a href="mailto:equipe@repetit.com.br">equipe@repetit.com.br</a> ou pelo WhatsApp{" "}
                    <a href="https://api.whatsapp.com/send?phone=5511976922404">(11) 97692-2404</a>. Conforme{" "}
                    <ButtonLink onClick={() => openPrivacyNTerms("terms")}>Termos e Condições</ButtonLink>
                    <br />
                    É preciso pagar uma taxa para retirá-la antes do período mínimo acordado de 12 meses.
                    <br />
                    <br />
                    <strong>Confira o valor da taxa para cada item:</strong>
                    <br />
                    Taxa para objetos e brinquedos grandes = R$ 50,00 por peça Taxa para roupas, acessórios, calçados e
                    pequenos objetos = R$ 6,00 por peça
                    <br />
                    <br />
                    <strong>Fique de olho!</strong>
                    <br />A Re Petit tem{" "}
                    <Underscore>7 dias úteis após o pedido de devolução para disponibilizar o item</Underscore> e
                    entrará em contato para agendar sua retirada.
                </Text>
            </QuestionContainer>

            <QuestionContainer title="17. Como sei que minha peça foi vendida? ">
                <Text>
                    Quando uma peça é vendida você recebe um e-mail com os detalhes. Ou basta acessar a sua conta usando
                    seu login e senha e confira todos os detalhes em <strong>MEUS PRODUTOS.</strong>
                    <br />
                    <br />O valor é liberado no site em <strong>30 dias corridos após a venda</strong> é o tempo que
                    pedimos para garantir que sua peça não foi devolvida. É só acessar a sua conta na Re Petit para
                    acompanhar o valor já disponível em <strong>MEUS CRÉDITOS.</strong>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="18. Como recebo o meu pagamento?">
                <Text>
                    O valor é liberado no site em <strong>30 dias após a venda!</strong> É só acessar a sua conta na Re
                    Petit para acompanhar o valor já disponível em <strong>MEU SALDO</strong>.
                    <br />
                    <br />
                    <strong>Escolha como prefere resgatar seu saldo:</strong>
                    <ol>
                        <li>
                            <strong>Transferência para conta bancária.</strong> Resgate seu dinheiro. Solicite a
                            transferência para sua conta bancária cadastrada. Para conferir a conta bancária cadastrada
                            entre em <strong>MINHA CONTA &gt; MEU CADASTRO &gt; DADOS PARA SAQUE DE SALDO</strong>. Para
                            efetuar saque entre em <strong>MINHA CONTA &gt; MEUS CRÉDITOS &gt; EFETUAR SAQUE</strong>.
                        </li>
                        <li>
                            <strong>Use seu saldo na Re Petit.</strong> Liberamos o valor em crédito para você resgatar
                            peças no site ou no BOX*, basta selecionar essa opção na finalização da compra, em{" "}
                            <strong>OPÇÕES DE PAGAMENTO &gt; USAR MEU CRÉDITO RE PETIT</strong>. Toda semana chegam
                            novidades! Espie só tudo o que você pode resgatar em{" "}
                            <RouterLink to="/produtos">COMPRE</RouterLink>
                        </li>
                    </ol>
                    <small>
                        O serviço de BOX está disponível apenas para a cidade de São Paulo e em cidades pontuais na
                        Grande São Paulo (Santo André, São Bernardo e São Caetano)
                    </small>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="19. Quais os canais de atendimento?">
                <Text>
                    Escolha o canal de atendimento para enviar sua dúvida, crítica ou sugestão!
                    <br />
                    <strong>Compras:</strong> <a href="mailto:vendas@repetit.com.br">vendas@repetit.com.br</a> ou{" "}
                    <a href="https://api.whatsapp.com/send?phone=5511990085518">(11) 99008-5518</a>
                    <br />
                    <strong>Desapegos:</strong> <a href="mailto:equipe@repetit.com.br">equipe@repetit.com.br</a> ou{" "}
                    <a href="https://api.whatsapp.com/send?phone=5511976922404">(11) 97692-2404</a>
                    <br />
                    <Flex justifyContent="flex-start">
                        <IconImage src={WhatsappIcon} />{" "}
                        <RouterLink to="https://api.whatsapp.com/send?phone=5511990085518">(11) 99008-5518</RouterLink>
                    </Flex>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="20. Como posso doar para as ONGs parceiras?">
                <Text>
                    Você pode optar por doar as peças que não foram selecionadas para venda ou que já estão há mais de
                    12 meses com a Re Petit. A cada ano escolhemos uma nova instituição para encaminhar as doações. É só
                    dar o seu OK que entregamos as peças para nossa ONG parceira.
                    <br />
                    <br />
                    Obrigado por seu apoio. Ficamos felizes em fazer o bem circular juntos!
                </Text>
            </QuestionContainer>
        </>
    );
};

export default HowToSell;
