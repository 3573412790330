import React from "react";
import styled from "styled-components";
import { Flex, isMobileMediaQuery } from "../../helpers/styles";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";

function TopHeaderCarroussel() {
    return (
        <TopHeader background="black" width="100%">
            <CarrousselContainer
                naturalSlideWidth={window.innerWidth}
                naturalSlideHeight={48}
                totalSlides={2}
                visibleSlides={1}
                interval={10000}
                dragEnabled={false}
                touchEnabled={false}
                isPlaying
            >
                <ContentSlider>
                    <StyledSlide>
                        <TopHeaderContent justifyContent="center" width="100%">
                            <Text>Parcele suas compras em até 4 vezes | Entregamos para todo Brasil</Text>
                        </TopHeaderContent>
                    </StyledSlide>

                    <StyledSlide>
                        <TopHeaderContent justifyContent="center" width="100%">
                            <Text>10% OFF sempre que usar seus créditos para comprar!</Text>
                        </TopHeaderContent>
                    </StyledSlide>

                    {/* <StyledSlide>
                        <TopHeaderContent justifyContent="center" width="100%">
                            <Text>
                                Frete Grátis compras acima de R$500,00 em peças selecionadas *válido para São Paulo
                            </Text>
                        </TopHeaderContent>
                    </StyledSlide> */}
                </ContentSlider>
            </CarrousselContainer>
        </TopHeader>
    );
}

const CarrousselContainer = styled(CarouselProvider)`
    flex: 1;
    height: auto;
    position: relative;
    width: 100%;
`;

const ContentSlider = styled(Slider)`
    li > div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    height: 48px;
    @media screen and (max-width: 1200px) {
        height: 44px;
    }
`;

const StyledSlide = styled(Slide)`
    display: flex;
    height: 48px;
    width: ${window.innerWidth}px;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1200px) {
        height: 44px;
    }
`;

const Text = styled.p`
    font-family: "Graphik";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: "white";
    box-sizing: border-box;

    font-weight: 600;

    ${isMobileMediaQuery} {
        font-size: 12px;
        text-align: center;
        line-height: 1.6;
        max-width: 360px;
        margin-top: 5px;
    }

    span {
        font-size: 14px;
        ${isMobileMediaQuery} {
            font-size: 10px;
        }
    }
`;

const TopHeaderBadge = styled.b`
    padding: 4px;
    width: 100%;
    margin-left: 5px;
    max-width: 80px;
    background-color: #e4858c;
    text-transform: uppercase;
    font-family: "Graphik";
    color: #222;
    border-radius: 2px;
    font-weight: 700;
    ${isMobileMediaQuery} {
        font-size: 12px;
    }
`;

const TopHeader = styled(Flex)`
    width: 100%;
    z-index: 6;
    background-color: #000;
    position: relative;
    justify-content: center;
    padding: "5px";
    color: #fff;
    background: "black";
    flex-wrap: "nowrap";
    flex-direction: "column";
    height: 48px;
    @media screen and (max-width: 1200px) {
        height: 58px;
    }
`;

const TopHeaderContent = styled(Flex)`
    justify-content: "flex-start";
    max-width: "1200px";
    width: "1200px";
    flex-direction: "column";
    justify-content: center;
`;
export default TopHeaderCarroussel;
