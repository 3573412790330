/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as UserActions from "../../redux/actions/user";
import api from "../../services/api";
import { Flex, isMobile, isMobileMediaQuery } from "../../helpers/styles";
import { capitalize, openPrivacyNTerms } from "../../helpers";
import Button from "../../components/Button";
import SectionTitle from "../../components/SectionTitle";
import CheckBox from "../../components/Inputs/CheckBox";
import CustomTable from "../../components/CustomTable";
import SellApprovalModal from "../../components/SellApprovalModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import ReactTooltip from "react-tooltip";
import ButtonLink from "../../components/ButtonLink";
import PromoModal from "../../components/PromoModal";
import Ilustra1 from "../../assets/images/account-vendas-1.png";
import Ilustra2 from "../../assets/images/account-vendas-2.png";
import Ilustra3 from "../../assets/images/account-vendas-3.png";
import CopyIcon from "../../assets/images/copyIcon.svg";
import InfograficoMobile from "../../assets/images/infografico-mobile.png";
import InfograficoWeb from "../../assets/images/infografico-web.png";
import PromoIcon from "../../assets/images/promo-icon.png";
import ChevronDownIcon from "../../assets/images/chevron-down.svg";
import ChevronRightIcon from "../../assets/images/chevron-right.png";
import SellProductItem from "./SellProductItem";

const MinhasVendas = ({ modal, setModal, item_id, history, ...props }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const product_filters = [
        {
            label: "Disponível",
            value: "available",
        },
        {
            label: "Em promoção",
            value: "available&promotion=true",
        },
        { label: "Vendido", value: "sold" },
        {
            label: "Devolvido",
            value: "returned",
        },
    ];

    const conservation_status = {
        brand_new: "Nunca usado",
        excelent: "Excelente",
        good: "Bom",
        used: "Usado",
    };

    const [pageState, setPageState] = useState(undefined);
    const [showModal, setShowModal] = useState(false);
    const [items, setItems] = useState(undefined);
    const [currentEntry, setCurrentEntry] = useState(undefined);

    const [loadingMoreItems, setLoadingMoreItems] = useState(false);

    const [showFilterContainer, setShowFilterContainer] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [showPromoModal, setShowPromoModal] = useState(false);
    const [errors, setErrors] = useState({});

    const [values, setValues] = useState({
        status: undefined,
        donate_unfit_items: undefined,
    });

    const promotedItemsOlderThanSixMonths =
        values.promotedItems &&
        values.promotedItems.filter(
            (item) => item.promotion_level !== undefined && item.promotion_level !== null && item.promotion_level >= 6
        );

    const copyText = `${window.location.origin}/loja/${user.slug}`;

    useEffect(() => {
        setShowModal(false);

        if (item_id) {
            handleLoadReviewBox(item_id);
        } else {
            setPageState("loading");
            loadItems();
        }

        // eslint-disable-next-line
    }, [history.pathname, item_id]);

    useEffect(() => {
        window.scrollTo({ top: 0 });

        // eslint-disable-next-line
    }, [pageState]);

    useEffect(() => {
        !item_id && loadItems();

        // eslint-disable-next-line
    }, [selectedFilter]);

    useEffect(() => {
        if (items?.items?.every((item) => values.promotedItems?.includes(item.id.toString()) || item.promotion)) {
            setValues((oldValues) => ({ ...oldValues, promotedAll: true }));
        } else {
            setValues((oldValues) => ({ ...oldValues, promotedAll: false }));
        }

        // eslint-disable-next-line
    }, [values.promotedItems]);

    const handleTableScroll = (e) => {
        const finalScroll = e.target.scrollHeight - e.target.offsetHeight;
        const currentScroll = e.target.scrollTop;

        if (finalScroll - currentScroll < 200) {
            handleLoadMoreItems();
        }
    };

    const handleChange = async (e) => {
        const {
            target: { name, value, checked, type },
        } = e;

        let newValues = { ...values };

        if (name !== undefined) {
            if (value !== undefined && (type !== "checkbox" || type === "radio")) {
                newValues = {
                    ...newValues,
                    [name]: value,
                };
            } else if (checked !== undefined) {
                if (name === "promotedItems") {
                    if (checked && !newValues.promotedItems?.includes(value)) {
                        newValues = {
                            ...newValues,
                            [name]: [...(newValues.promotedItems || []), value],
                        };
                    } else if (!checked) {
                        newValues = {
                            ...newValues,
                            [name]: (newValues.promotedItems || []).filter((item) => item !== value),
                        };
                    }
                } else {
                    newValues = {
                        ...newValues,
                        [name]: checked,
                    };
                }

                if (name === "promotedAll") {
                    newValues.promotedItems = checked
                        ? items?.items?.reduce((acc, curr) => {
                              return curr.promotion ? acc : [...acc, curr.id.toString()];
                          }, [])
                        : [];
                }
            }
        }

        if (name === "withdraw_value") {
            newValues[name] = value.replace(/(?:(?!,|[0-9]).)+/g, "");

            const withdrawValue = parseFloat(newValues.withdraw_value.replace(",", "."));
            const minimumAmount = 5;
            const availableAmount = user.balance || 0;
            const { withdraw_tax } = user;
            if (withdrawValue < minimumAmount) {
                setErrors((currState) => ({
                    ...currState,
                    withdraw_value: `O valor mínimo para saque é de R$ ${minimumAmount}`,
                }));
            } else if (withdrawValue > availableAmount || (withdrawValue > availableAmount - 3 && withdraw_tax > 0)) {
                setErrors((currState) => ({
                    ...currState,
                    withdraw_value: `Você precisa digitar uma valor menor ou igual a R$ ${
                        availableAmount - withdraw_tax
                    }`,
                }));
            } else {
                setErrors((currState) => ({
                    ...currState,
                    withdraw_value: false,
                }));
            }
        }

        setValues((prevState) => ({ ...prevState, ...newValues }));
    };

    const loadItems = async () => {
        setValues((val) => ({ ...val, promotedItems: [] }));
        setItems(null);

        const response = await api
            .get(`/profiles/entries/items${selectedFilter ? `?status=${selectedFilter}` : ""}`)
            .catch((err) => err.response);

        setPageState("home");

        if (response.status === 200) {
            setItems({
                ...response.data,
            });
        } else {
            setItems({
                items: [],
                pagination: {},
            });
        }
    };

    const handleLoadMoreItems = async () => {
        if (!items || !items.pagination || !items.pagination.next_page_path || loadingMoreItems) {
            return;
        }

        setLoadingMoreItems(true);

        const response = await api.get(items.pagination.next_page_path.replace("/v1/", ""));

        setLoadingMoreItems(false);

        const { scrollY } = window;

        if (response.status === 200) {
            setItems({
                ...items,
                items: response.data.items,
                pagination: response.data.pagination,
                scrollY,
            });
        }
    };

    const handleLoadPrevPage = async () => {
        if (
            !items ||
            !items.pagination ||
            !items.pagination.next_page_path ||
            loadingMoreItems ||
            items.pagination.skip < items.pagination.per_page
        ) {
            return;
        }

        window.scrollTo({ top: 0 });

        setLoadingMoreItems(true);

        const response = await api.get(
            items.pagination.next_page_path
                .replace("/v1/", "")
                .replace(`skip=${items.pagination.skip}`, `skip=${items.pagination.skip - items.pagination.per_page}`) +
                (selectedFilter ? `&status=${selectedFilter}` : "")
        );

        setLoadingMoreItems(false);

        if (response.status === 200) {
            setItems({
                ...items,
                items: response.data.items,
                pagination: response.data.pagination,
            });
        }
    };

    const handleLoadNextPage = async (page = null) => {
        if (
            !items ||
            !items.pagination ||
            !items.pagination.next_page_path ||
            loadingMoreItems ||
            page < 0 ||
            page > Math.floor(items.pagination.total / items.pagination.per_page)
        ) {
            return;
        }

        window.scrollTo({ top: 0 });

        setLoadingMoreItems(true);

        const response = await api.get(
            (page !== null
                ? items.pagination.next_page_path
                      .replace("/v1/", "")
                      .replace(
                          `skip=${items.pagination.skip + items.pagination.per_page}`,
                          `skip=${page * items.pagination.per_page}`
                      )
                : items.pagination.next_page_path.replace("/v1/", "")) +
                (selectedFilter ? `&status=${selectedFilter}` : "")
        );

        setLoadingMoreItems(false);

        if (response.status === 200) {
            setItems({
                ...items,
                items: response.data.items,
                pagination: response.data.pagination,
            });
        }
    };

    const handleShowLotes = () => {
        navigate("/minha-conta/vendas");
    };

    const handleLoadReviewBox = async (entry_id) => {
        const box_review_response = await api.get(`/profiles/entries/${entry_id}`).catch((err) => err.response);

        if (box_review_response.status === 200) {
            setCurrentEntry(box_review_response.data);
            setPageState("review_values");
        } else {
            setPageState("home");

            navigate("/minha-conta/vendas");
        }
    };

    const handleSendReviewValues = async () => {
        const box_review_response = await api
            .put(`/profiles/entries/${item_id}`, {
                status: values.status,
                donate_unfit_items: values.donate_unfit_items === "true",
            })
            .catch((err) => err.response);

        if (
            box_review_response.status === 200 &&
            box_review_response.data &&
            box_review_response.data.status !== "pending"
        ) {
            setShowModal(true);
            dispatch(UserActions.refreshProfile());
        } else {
            console.error(box_review_response);

            setModal({
                show: true,
                message:
                    box_review_response && box_review_response.data && box_review_response.data.message
                        ? box_review_response.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
    };

    return (
        <>
            {(pageState === undefined || pageState === "loading") && (
                <>
                    <LoadingSpinner dark size={24} />
                </>
            )}

            {items?.items?.length === 0 && pageState === "home" && (
                <>
                    <SectionTitle margin="0 0 36px 0" barWidth="9%">
                        venda com a re petit e faça o bem!
                    </SectionTitle>

                    <Flex justifyContent="space-between" width="100%" margin="0 0 40px 0">
                        <InfoCard padding={!isMobile && "28px 15px 0 16px"}>
                            <Flex direction="column" desktop>
                                <h1>
                                    desapegue do que não <br />
                                    circula mais na sua casa
                                </h1>
                                <hr />
                            </Flex>

                            <img src={Ilustra1} alt="Ilustração 1" />
                            <hr />

                            <Flex maxWidth="70%" alignItems="flex-start" direction="column">
                                <Flex mobile>
                                    <h1>
                                        desapegue do que não <br />
                                        circula mais na sua casa
                                    </h1>
                                </Flex>
                                <p>
                                    Roupas, calçados, acessórios e utensílios infantis bem conservados das marcas
                                    aceitas.
                                </p>
                            </Flex>
                        </InfoCard>

                        <InfoCard>
                            <Flex direction="column" desktop>
                                <h1>
                                    envie tudo <br /> para a gente
                                </h1>
                                <hr />
                            </Flex>

                            <img src={Ilustra2} alt="Ilustração 2" />
                            <hr />

                            <Flex maxWidth="70%" alignItems="flex-start" direction="column">
                                <Flex mobile>
                                    <h1>
                                        envie tudo <br /> para a gente
                                    </h1>
                                </Flex>
                                <p>
                                    Vamos avaliar as peças, fotografar cada uma delas e fazer todo o processo de venda
                                    para você!
                                </p>
                            </Flex>
                        </InfoCard>

                        <InfoCard>
                            <Flex direction="column" desktop>
                                <h1>
                                    todo mundo
                                    <br /> sai ganhando!
                                </h1>
                                <hr />
                            </Flex>

                            <img src={Ilustra3} alt="Ilustração 3" />
                            <hr />

                            <Flex maxWidth="70%" alignItems="flex-start" direction="column">
                                <Flex mobile>
                                    <h1>
                                        todo mundo
                                        <br /> sai ganhando!
                                    </h1>
                                </Flex>
                                <p>
                                    Quem vende ganha uma renda extra, quem compra paga menos e o planeta economiza seus
                                    recursos naturais.
                                </p>
                            </Flex>
                        </InfoCard>
                    </Flex>

                    {items?.items?.length > 0 && (
                        <Button
                            width="280px"
                            padding="15px 0"
                            margin="0 auto"
                            dark
                            value="acompanhar minhas vendas"
                            onClick={handleShowLotes}
                        />
                    )}
                </>
            )}

            {items?.items.length > 0 && pageState === "home" && (
                <>
                    <>
                        <SectionTitle margin="0 0 36px 0" barWidth="9%">
                            Meus produtos na re petit
                        </SectionTitle>

                        <Text>Aqui você acompanha e controla o status dos seus produtos</Text>

                        <Flex
                            border="1px solid black"
                            margin="20px 0 0 0"
                            padding={"0 0 0 10px"}
                            maxWidth={isMobile && "390px"}
                            direction={"row"}
                            wrap={isMobile && "no-wrap"}
                        >
                            {!isMobile && (
                                <>
                                    <Text margin={"0 5px"} dark>
                                        COPIE O LINK DA SUA LOJA E COMPARTILHE:
                                    </Text>

                                    <Separation />
                                    <Text margin={"0 20px 0 10px"} dark>
                                        {copyText}
                                    </Text>
                                </>
                            )}

                            <CopyToClipboard text={copyText}>
                                <Button
                                    id="copy-btn"
                                    width={isMobile ? "290px" : "40px"}
                                    filled
                                    margin={isMobile ? "0" : "0 0 0 10px"}
                                >
                                    <>
                                        {isMobile && (
                                            <Text margin={"0 20px 0 0"} dark>
                                                COPIE O LINK DA SUA LOJA
                                            </Text>
                                        )}
                                        <img width="20px" height="auto" src={CopyIcon} alt="Copiar" />
                                    </>
                                </Button>
                            </CopyToClipboard>
                        </Flex>

                        <TableHeader width="100%" margin="33px 0 30px 0">
                            <Flex margin="auto 0">
                                <CheckBox checked={values.promotedAll} name="promotedAll" onChange={handleChange} />

                                <ReactTooltip />
                                <span
                                    data-tip={
                                        values.promotedItems?.length > 0 && promotedItemsOlderThanSixMonths.length === 0
                                            ? "Nenhum produto selecionado tem pelo ao menos 6 meses."
                                            : ""
                                    }
                                >
                                    <Button
                                        dark
                                        link
                                        disabled={
                                            !(values.promotedItems?.length > 0) ||
                                            promotedItemsOlderThanSixMonths.length === 0
                                        }
                                        onClick={() => setShowPromoModal(!showPromoModal)}
                                    >
                                        <PromoImage />
                                        Ativar promoção
                                    </Button>
                                </span>
                            </Flex>
                            <Flex margin="auto 0" position="relative" justifyContent="flex-end">
                                <FilterButton onClick={() => setShowFilterContainer(!showFilterContainer)}>
                                    Filtrar por{" "}
                                    {!selectedFilter ? (
                                        "status"
                                    ) : (
                                        <span>
                                            {product_filters.find((item) => item.value === selectedFilter).label}
                                        </span>
                                    )}
                                    <ChevronDown active={showFilterContainer} />
                                </FilterButton>

                                {showFilterContainer && (
                                    <FilterContainer>
                                        {product_filters.map((item) => (
                                            <p
                                                className={selectedFilter === item.value ? "active" : ""}
                                                onClick={() => {
                                                    setSelectedFilter(
                                                        selectedFilter === item.value ? null : item.value
                                                    );
                                                    setShowFilterContainer(false);
                                                }}
                                            >
                                                {item.label}
                                            </p>
                                        ))}
                                    </FilterContainer>
                                )}
                            </Flex>
                        </TableHeader>

                        <ProductsList
                            // onScroll={(e) => handleTableScroll(e)}
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            width="100%"
                            direction="column"
                            // maxHeight="80vh"
                            // overflow="hidden auto"
                            boxSizing="border-box"
                            wrap="no-wrap"
                            margin="0"
                        >
                            {loadingMoreItems ? (
                                <LoadingSpinner margin="0 auto" dark size={36} />
                            ) : (
                                items?.items?.map((item, index) => (
                                    <SellProductItem
                                        key={index}
                                        item={item}
                                        showId={!isMobile}
                                        handleChange={handleChange}
                                        checked={values.promotedItems?.includes(item.id.toString())}
                                        setValues={setValues}
                                        showPromoModal={showPromoModal}
                                        setShowPromoModal={setShowPromoModal}
                                    />
                                ))
                            )}
                        </ProductsList>

                        <Flex margin="0 auto 32px auto">
                            <Button
                                dark
                                filled
                                disabled={items.pagination.skip < items.pagination.per_page}
                                onClick={handleLoadPrevPage}
                                margin="auto 30px auto 0"
                                padding="10.5px"
                            >
                                <ChevronRight left />
                            </Button>
                            {[...Array(Math.round(items.pagination.total / items.pagination.per_page)).keys()]
                                .slice(0, isMobile ? 3 : 6)
                                .map((item, index) => (
                                    <Button
                                        key={`page_${index + 1}`}
                                        padding="7px 10px"
                                        dark
                                        filled={index === Math.floor(items.pagination.skip / items.pagination.per_page)}
                                        onClick={() =>
                                            index !== Math.floor(items.pagination.skip / items.pagination.per_page) &&
                                            handleLoadNextPage(index)
                                        }
                                        margin="auto 10px auto 0"
                                        small
                                    >
                                        {index + 1}
                                    </Button>
                                ))}

                            {Math.floor(items.pagination.total / items.pagination.per_page) > (isMobile ? 3 : 6) && (
                                <>
                                    {" "}
                                    ...{" "}
                                    <Button
                                        padding="7px 10px"
                                        dark
                                        filled={
                                            Math.floor(items.pagination.total / items.pagination.per_page) ===
                                            Math.floor(items.pagination.skip / items.pagination.per_page)
                                        }
                                        onClick={() =>
                                            Math.floor(items.pagination.total / items.pagination.per_page) !==
                                                Math.floor(items.pagination.skip / items.pagination.per_page) &&
                                            handleLoadNextPage(
                                                Math.floor(items.pagination.total / items.pagination.per_page)
                                            )
                                        }
                                        margin="auto 0 auto 10px"
                                        small
                                    >
                                        {Math.floor(items.pagination.total / items.pagination.per_page)}
                                    </Button>
                                </>
                            )}

                            <Button
                                dark
                                filled
                                disabled={items.pagination.skip >= items.pagination.total - items.pagination.per_page}
                                onClick={() => handleLoadNextPage()}
                                margin="auto 0 auto 30px"
                                padding="10.5px"
                            >
                                <ChevronRight />
                            </Button>
                        </Flex>

                        <Text>
                            Caso você queira uma devolução antes do prazo de 12 meses, por favor entre em contato com a
                            nossa equipe para saber mais. Não esqueçam de consultar nossos Termos de Condições.
                        </Text>

                        {/* <Flex margin="35px auto 40px auto">
                            <Button
                                width="280px"
                                margin="0 auto 0 auto"
                                filled
                                dark
                                value="salvar promoções"
                                onClick={() => handleSubmitPromotions()}
                            />
                        </Flex>

                        <Separator />

                        <Title>
                            ative a promoção e aumente suas chances de venda!
                        </Title>

                        <Text maxWidth="686px">
                            Suas peças ficam com a Re Petit por até 1 anos e
                            durante este período você pode ativar descontos
                            progressivos no valor de venda. É só clicar na
                            coluna PROMOÇÃO para ativar descontos no valor de
                            venda das suas peças: 20% após os 6 meses de
                            contrato e 50% após os 9 meses.
                        </Text> */}
                    </>

                    <>
                        <SectionTitle margin="35px 0 30px 0">desconto progressivo</SectionTitle>

                        <Infografico src={isMobile ? InfograficoMobile : InfograficoWeb} />
                    </>
                </>
            )}

            {pageState === "review_values" && currentEntry !== undefined ? (
                <>
                    <SectionTitle margin="0 0 30px 0">aprovação do valor de venda</SectionTitle>

                    <Flex
                        direction="column"
                        width="100%"
                        margin="0 0 40px 0"
                        boxShadow="0px 1px 2px 0 rgba(52, 52, 59, 0.15);"
                    >
                        <Flex padding="20px 0px" width="100%" background="#F7F9F9">
                            <ResumeTitle>resumo da avaliação das peças</ResumeTitle>
                        </Flex>

                        <Flex
                            width="100%"
                            alignItems="flex-start"
                            margin="1px 0 0 0"
                            direction={isMobile ? "column" : "row"}
                        >
                            <Flex
                                justifyContent="flex-start"
                                width={isMobile ? "100%" : "50%"}
                                flex="1"
                                margin="0 1px 0 0"
                                padding="20px 0px 20px 30px"
                                background="#F3F5F5"
                                boxSizing="border-box"
                            >
                                <CardBoxTitle>
                                    total de peça recebidas:{" "}
                                    {((currentEntry.items && currentEntry.items.length) || 0) +
                                        (currentEntry.quantity_unfit_items || 0)}
                                </CardBoxTitle>
                            </Flex>
                            <Flex
                                justifyContent="flex-start"
                                width={isMobile ? "100%" : "50%"}
                                flex="1"
                                padding="20px 0px 20px 30px"
                                background="#F3F5F5"
                                boxSizing="border-box"
                            >
                                <CardBoxTitle>
                                    total de peças aprovadas: {(currentEntry.items && currentEntry.items.length) || 0}
                                </CardBoxTitle>
                            </Flex>
                        </Flex>

                        <Flex
                            width="100%"
                            alignItems="flex-start"
                            margin="1px 0 0 0"
                            direction={isMobile ? "column" : "row"}
                        >
                            <Flex
                                justifyContent="flex-start"
                                width={isMobile ? "100%" : "50%"}
                                flex="1"
                                padding="20px 0px 20px 30px"
                                margin="0 1px 0 0"
                                background="#F7F9F9"
                                boxSizing="border-box"
                            >
                                <CardBoxTitle>
                                    total de peça não aprovadas: {currentEntry.quantity_unfit_items || 0}
                                </CardBoxTitle>
                            </Flex>
                            <Flex
                                justifyContent="flex-start"
                                width={isMobile ? "100%" : "50%"}
                                flex="1"
                                padding="20px 0px 20px 30px"
                                background="#F7F9F9"
                                boxSizing="border-box"
                            >
                                <CardBoxTitle>
                                    valor estimado do lote:{" "}
                                    <MoneyTag>
                                        R${" "}
                                        {(currentEntry.total_price || 0).toLocaleString("pt-BR", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </MoneyTag>
                                </CardBoxTitle>
                            </Flex>
                        </Flex>
                    </Flex>

                    <CustomTable
                        width="100%"
                        boxShadow="0px 1px 2px 0 rgba(52, 52, 59, 0.15)"
                        margin="0 0 40px 0"
                        maxHeight="50vh"
                        overflow="auto"
                        columns={[
                            { label: "Item", name: "item", width: "25%" },
                            { label: "Marca", name: "marca", width: "25%" },
                            {
                                label: "Estado de conservação",
                                name: "conservation",
                                width: "25%",
                            },
                            {
                                label: "Valor A Receber*",
                                name: "receber",
                            },
                        ]}
                        rows={currentEntry.items.reduce(
                            (acc, curr) => [
                                ...acc,
                                {
                                    item: capitalize(`${curr.item_category.name.toLowerCase()}`),
                                    conservation:
                                        curr.conservation || curr.item?.conservation
                                            ? conservation_status[curr.conservation || curr.item?.conservation]
                                            : "-",
                                    marca: capitalize(curr.brand.name),
                                    receber: `R$ ${curr.price_supplier.toLocaleString("pt-br", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}`,
                                },
                            ],
                            []
                        )}
                    />

                    <Flex
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        width="100%"
                        flex="1"
                        padding={isMobile ? "0 20px" : undefined}
                        boxSizing="border-box"
                    >
                        <Text align="left" margin="0px auto 18px 0px">
                            *<b>VALOR A RECEBER:</b> é o quanto você ganha se sua peça for vendida. Não é o valor da
                            venda do produto.
                        </Text>

                        <Flex
                            direction="column"
                            width="100%"
                            margin="0 0 20px 0"
                            boxShadow="0px 1px 2px 0 rgba(52, 52, 59, 0.15);"
                        >
                            <Flex
                                justifyContent="flex-start"
                                padding="20px 20px"
                                width="100%"
                                background="#F7F9F9"
                                boxSizing="border-box"
                            >
                                <CardBoxTitle>
                                    VALOR TOTAL: R${" "}
                                    {(currentEntry.total_price || 0).toLocaleString("pt-BR", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </CardBoxTitle>
                            </Flex>

                            <Flex
                                justifyContent="flex-start"
                                width="100%"
                                margin="0 1px 0 0"
                                padding="20px 0px 20px 30px"
                                background="#F7F9F9"
                                boxSizing="border-box"
                            >
                                <CheckBox
                                    name="status"
                                    value="approved"
                                    radio
                                    onChange={handleChange}
                                    defaultChecked={currentEntry.status === "approved"}
                                    disabled={currentEntry.status !== "pending"}
                                >
                                    <Text>Estou de acordo e quero vender</Text>
                                </CheckBox>
                            </Flex>

                            <Flex
                                justifyContent="flex-start"
                                width="100%"
                                padding="20px 0px 20px 30px"
                                background="#F7F9F9"
                                boxSizing="border-box"
                            >
                                <CheckBox
                                    name="status"
                                    value="denied"
                                    radio
                                    onChange={handleChange}
                                    defaultChecked={currentEntry.status === "denied"}
                                    disabled={currentEntry.status !== "pending"}
                                >
                                    <Text>Não estou de acordo</Text>
                                </CheckBox>
                            </Flex>
                        </Flex>

                        <Separator />

                        <Text align="left" margin="28px auto 17px 0px">
                            As peças não aprovadas não passaram no critério de aceitação da Re Petit e podem ser doadas
                            para nossa ONG parceira.
                        </Text>

                        <Flex
                            direction="column"
                            width="100%"
                            margin="0"
                            boxShadow="0px 1px 2px 0 rgba(52, 52, 59, 0.15);"
                        >
                            <Flex
                                justifyContent="flex-start"
                                padding="20px 20px"
                                width="100%"
                                background="#F7F9F9"
                                boxSizing="border-box"
                            >
                                <CardBoxTitle>PEÇAS NÃO APROVADAS</CardBoxTitle>
                            </Flex>

                            <Flex
                                justifyContent="flex-start"
                                width="100%"
                                margin="0 1px 0 0"
                                padding="20px 0px 20px 30px"
                                background="#F7F9F9"
                                boxSizing="border-box"
                            >
                                <CheckBox
                                    name="donate_unfit_items"
                                    value="true"
                                    radio
                                    onChange={handleChange}
                                    defaultChecked={
                                        currentEntry.donate_unfit_items && currentEntry.status !== "pending"
                                    }
                                    disabled={currentEntry.status !== "pending"}
                                >
                                    <Text>Doar peças</Text>
                                </CheckBox>
                            </Flex>

                            <Flex
                                justifyContent="flex-start"
                                width="100%"
                                padding="20px 0px 20px 30px"
                                background="#F7F9F9"
                                boxSizing="border-box"
                            >
                                <CheckBox
                                    name="donate_unfit_items"
                                    value="false"
                                    radio
                                    onChange={handleChange}
                                    defaultChecked={
                                        !currentEntry.donate_unfit_items && currentEntry.status !== "pending"
                                    }
                                    disabled={currentEntry.status !== "pending"}
                                >
                                    <Text>Retirar peças</Text>
                                </CheckBox>
                            </Flex>
                        </Flex>

                        <Text dark align="left" margin="21px auto 37px 0px">
                            <b>IMPORTANTE:</b>
                            <ul>
                                <li>
                                    Caso a proposta não seja respondida em até 7 dias corridos, será aprovada
                                    automaticamente e as peças seguirão para venda.
                                </li>
                                <li>
                                    Você tem 10 dias corridos para retirar as peças não aprovadas. Após este prazo suas
                                    peças serão automaticamente doadas. A retirada é das peças é de responsabilidade do
                                    vendedor. Endereço: Rua Andréa Paulinetti, 53 - CEP 04707-050, Brooklin, São
                                    Paulo/SP.
                                </li>
                                <li>
                                    É obrigatório que o CPF da conta bancária cadastrada seja o mesmo do cadastro de
                                    usuário no site.
                                </li>
                            </ul>
                        </Text>

                        <CheckBox
                            width="100%"
                            margin="0 auto 0 0"
                            label={
                                <>
                                    Eu li e aceito os{" "}
                                    <ButtonLink onClick={() => openPrivacyNTerms("terms")}>
                                        Termos e Condições
                                    </ButtonLink>{" "}
                                    e{" "}
                                    <ButtonLink onClick={() => openPrivacyNTerms("privacy")}>
                                        Política de Privacidade
                                    </ButtonLink>
                                </>
                            }
                            name="accept_terms"
                            value={values.accept_terms}
                            onChange={handleChange}
                            defaultChecked={currentEntry.status !== "pending"}
                            disabled={currentEntry.status !== "pending"}
                        />
                    </Flex>

                    <Flex
                        margin={isMobile ? "32px 20px 40px 20px" : "35px auto 40px auto"}
                        direction={isMobile ? "column" : "row"}
                    >
                        {currentEntry.status === "pending" && (
                            <Button
                                width={isMobile ? "320px" : "280px"}
                                margin={isMobile ? "0 auto 20px auto" : "0 35px 0 auto"}
                                dark
                                value="confirmar"
                                disabled={values.accept_terms !== true || !values.status || !values.donate_unfit_items}
                                onClick={handleSendReviewValues}
                            />
                        )}
                        {currentEntry.status !== "pending" && (
                            <Button
                                width={isMobile ? "320px" : "280px"}
                                margin={isMobile ? "0 auto" : "0 auto 0 0"}
                                filled
                                dark
                                value="voltar"
                                onClick={() => handleShowLotes()}
                            />
                        )}
                    </Flex>
                </>
            ) : null}

            {showModal && (
                <SellApprovalModal
                    callback={() => {
                        navigate("/");
                    }}
                    show
                    approved={values.status === "approved"}
                />
            )}

            {items?.items && values.promotedItems && promotedItemsOlderThanSixMonths.length > 0 && (
                <PromoModal
                    items={items.items}
                    promotedItems={promotedItemsOlderThanSixMonths}
                    show={showPromoModal}
                    setModal={setModal}
                    callback={(status) => setShowPromoModal(status)}
                    successCallback={() => loadItems()}
                />
            )}
        </>
    );
};

const InfoCard = styled(Flex)`
    width: 279px;
    height: 430px;
    padding: ${(props) => (props.padding ? props.padding : "28px 30px 0px 30px")};
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);
    justify-content: flex-start;
    flex-direction: column;
    background-color: #fbfcfc;
    box-sizing: border-box;
    flex-wrap: nowrap;

    h1 {
        text-transform: uppercase;
        margin: 0px auto 0px auto;

        font-family: "SackersGothicStd";
        font-size: 11.5px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
        letter-spacing: 1.65px;
        text-align: center;
        color: #34343b;
    }

    img {
        height: auto;
        max-height: 142px;
        object-fit: contain;
    }

    hr {
        width: 79px;
        height: 1px;
        min-height: 1px;
        margin: 0px auto;
        border: none;
        background-color: #313139;

        :first-of-type {
            margin-top: 26px;
            margin-bottom: 40px;
        }

        :last-of-type {
            margin-top: 40px;
            margin-bottom: 26px;
        }
    }

    p {
        margin: 0px;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: #676777;
    }

    ${isMobileMediaQuery} {
        width: 100%;
        height: auto;
        min-height: 170px;
        flex-direction: row;
        margin: 0 20px;
        justify-content: center;

        :not(:last-of-type) {
            margin-bottom: 20px;
        }

        h1 {
            font-size: 11.5px;
            text-align: left;
            margin-bottom: 16px;
        }

        img {
            width: 81px;
            height: 119px;
            object-fit: contain;
        }

        hr {
            width: 1px;
            min-width: 1px;
            height: 100%;
            min-height: 79px;
            margin: auto 20px auto 26px;
        }

        p {
            font-size: 13px;
            text-align: left;
        }
    }
`;

const Separation = styled.div`
    display: flex;
    position: relative;
    width: 1.5px;
    background: #e5e5e8;
    height: 2em;
    margin: 0 5px;
`;

const FooterText = styled.p`
    margin: ${(props) => props.margin || "34px auto 0 auto"};
    max-width: ${(props) => props.maxWidth || "430px"};

    font-family: "Graphik";
    font-size: 13px;
    line-height: 1.25;
    text-align: right;
    color: #34343b;

    box-sizing: border-box;

    ${isMobileMediaQuery} {
        text-align: center;
    }

    ${(props) => props.danger && "color: red;"};
`;

const Text = styled.p`
    margin: ${(props) => props.margin || "0px"};
    max-width: ${(props) => props.maxWidth};

    font-family: "Graphik";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: ${(props) => props.align || "center"};
    color: ${(props) => (props.dark ? "#34343b" : "#676777")};
    box-sizing: border-box;

    b {
        font-weight: 600;
    }
`;

const InputText = styled.input`
    margin: ${(props) => props.margin || "0px"};
    max-width: ${(props) => props.maxWidth};

    font-family: "Graphik";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: ${(props) => props.align || "center"};
    color: ${(props) => (props.dark ? "#34343b" : "#676777")};
    box-sizing: border-box;
    border: none;

    b {
        font-weight: 600;
    }
`;

const Separator = styled.hr`
    background-color: ${(props) => (props.light ? "#fff" : "#e5e5e8")};
    margin: ${(props) => props.margin};
    width: ${(props) => props.width || "100%"};
    height: 1px;
    border: none;
`;

const Title = styled.h2`
    text-transform: uppercase;
    margin: 0px;

    font-family: "SackersGothicStd";
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 4.54;
    letter-spacing: 2.8px;
    text-align: center;
    color: #34343b;
`;

const CardBoxTitle = styled.h2`
    margin: ${({ margin }) => margin || "0px 0px 16px 0px"};
    text-transform: uppercase;
    font-size: 15px;
    font-family: "Graphik";
    font-weight: 600;
    color: #34343b;

    :only-child,
    :last-child {
        margin: 0px;
    }
`;

const CardBox = styled(Flex)`
    padding: ${(props) => (props.padding ? props.padding : "34px 28px")};
    background-color: #f7f9f9;
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);
    border-bottom: ${(props) => !props.noBorder && "2px solid #59cd8e"};
    position: relative;
`;

const EditCard = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 36px;
    text-decoration: underline;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.28px;
    text-align: left;
    color: #676777;
`;

const TextSection = styled.p`
    margin: ${({ margin }) => margin || "0px 0px 16px 0px"};
    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #676777;
    white-space: pre-wrap;

    a,
    strong {
        color: #676777;
        font-weight: 600;
        ${(props) => props.danger && "color: red;"};
    }

    ${(props) => props.danger && "color: red;"};

    ${isMobileMediaQuery} {
        max-width: 80%;
    }
`;

const HistoryTable = styled.table`
    width: 100%;
    padding: 0px;
    text-transform: uppercase;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #676777;

    tr:nth-child(odd) {
        background-color: #f3f5f5;
    }

    .in {
        color: #0e7d0e;
    }
    .out {
        color: #d70000;
    }

    td,
    th {
        padding: 8px 0px;

        :only-child {
            text-align: center;
        }

        :first-child {
            padding-left: 37px;
        }
        :last-child:not(:only-child) {
            padding-right: 37px;
            text-align: right;
        }
    }

    th {
        padding-top: 20px;
        padding-bottom: 20px;
    }
`;

const ResumeTitle = styled.h1`
    text-transform: uppercase;
    margin: 0px;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 2.2px;
    text-align: center;
    color: #34343b;
`;

const MoneyTag = styled.span`
    margin: 0px;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #79c992;
`;

const ErrorText = styled.p`
    color: red;
    margin-bottom: 0px;
`;

const Infografico = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;

    ${isMobileMediaQuery} {
        max-width: 360px;
        margin: 0 auto 0 auto;
    }
`;

const TableHeader = styled(Flex)`
    border-top: 1px solid #cacad1;
    border-bottom: 1px solid #cacad1;

    justify-content: space-between;

    ${isMobileMediaQuery} {
        align-items: center;
        justify-content: center;
    }
`;

const ProductsList = styled(Flex)`
    margin-bottom: 40px;

    & > div:not(:last-child) {
        padding-bottom: 26px;
        margin-bottom: 26px;
        border-bottom: 1px solid #e5e5e8;
    }
`;

export const FilterButton = styled.p`
    margin: 0;
    padding: 16px;

    font-family: "Graphik";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.28px;
    text-align: left;
    color: #676777;
    cursor: pointer;

    ${(props) =>
        props.active &&
        css`
            color: #e4858c;
        `}

    span {
        color: #e4858c;
        text-decoration: none;
    }
`;

const FilterContainer = styled.div`
    position: absolute;
    top: calc(100% - 6px);
    right: 30px;
    width: 100px;
    min-width: 156px;
    padding: 16px;
    border-radius: 4px;
    background-color: #f3f5f5;
    box-shadow: 0px 2px 1px 0 rgba(52, 52, 64, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 5;

    p {
        margin: 0px;

        font-family: "Graphik";
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 17.5px;
        letter-spacing: normal;
        text-align: left;
        color: #8c8ca1;
        cursor: pointer;

        :hover,
        &.active {
            color: #e4858c;
        }
    }
`;

const PromoImage = styled.img.attrs(() => ({
    src: PromoIcon,
    alt: "Promo",
}))`
    width: 20px;
    height: auto;
    margin-right: 7px;
`;

const ChevronDown = styled.img.attrs(() => ({
    src: ChevronDownIcon,
    alt: "Chevron Down",
}))`
    width: 14px;
    height: 8px;
    object-fit: contain;
    margin-left: 8px;

    ${({ active }) =>
        active &&
        css`
            transform: scaleY(-1);
        `}
`;

const ChevronRight = styled.img.attrs((props) => ({
    src: ChevronRightIcon,
    alt: props.left ? "Chevron Left" : "Chevron Right",
}))`
    width: 17px;
    height: 27px;
    object-fit: contain;

    ${({ left }) =>
        left &&
        css`
            transform: scaleX(-1);
        `}
`;

export default MinhasVendas;
