import axios from "axios";
import store from "../redux/store";

import * as UserActions from "../redux/actions/user";
import * as ModalActions from "../redux/actions/modal";
import * as LoginModalActions from "../redux/actions/loginModal";

export const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
    baseURL,
});

api.interceptors.request.use((config) => {
    const { token } = store.getState().user || {};

    if (token !== null && token !== undefined) {
        config.headers.Authorization = token;
    }

    return config;
});

api.interceptors.response.use(
    (response) => {
        const { dispatch } = store;
        const { token } = store.getState().user || null;

        if (
            response.headers &&
            response.headers.token &&
            response.headers.token !== token
        ) {
            dispatch(UserActions.refreshProfile());
        }

        if (
            response &&
            response.data &&
            response.data.message &&
            response.data.message.indexOf("expired") > 0
        ) {
            dispatch(UserActions.logout());

            dispatch(
                ModalActions.show({
                    title: "Ops!",
                    message:
                        "Sua sessão expirou, por favor faça login novamente e continue com sua compra.",
                    callback: () =>
                        dispatch(
                            LoginModalActions.show(window.location.pathname)
                        ),
                })
            );
        }

        return response;
    },
    function (error) {
        const { dispatch } = store;

        if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message.indexOf("expired") > 0
        ) {
            dispatch(UserActions.logout());

            dispatch(
                ModalActions.show({
                    title: "Ops!",
                    message:
                        "Sua sessão expirou, por favor faça login novamente e continue com sua compra.",
                    callback: () =>
                        dispatch(
                            LoginModalActions.show(window.location.pathname)
                        ),
                })
            );
        }

        return Promise.reject(error);
    }
);

export default api;
