import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Flex, isMobile, isMobileMediaQuery } from "../../helpers/styles";

const BrandsGrid = ({ brands, subCategory, ...props }) => {
    const letters = "#abcdefghijklmnopqrstuvwxyz";
    const [currentItem, setCurrentItem] = useState("#");

    useEffect(() => {
        letters.split("").every((item, index) => {
            if (brands && brands[item.toUpperCase()] && brands[item.toUpperCase()].length > 0) {
                setCurrentItem(item);
                return false;
            }
            return true;
        });

        // eslint-disable-next-line
    }, []);

    return (
        <Container>
            <Flex
                direction="column"
                width="100%"
                flex="1"
                alignItems={isMobile ? "flex-start" : "center"}
                justifyContent="flex-start"
                maxWidth="100%"
                overflow="auto"
            >
                <NavRow>
                    {letters.split("").map((item, index) => (
                        <NavItem
                            key={`brandGridNavItem${index}`}
                            active={currentItem === item.toLowerCase()}
                            onMouseEnter={() => setCurrentItem(item.toLowerCase())}
                            disabled={!brands || !brands[item.toUpperCase()] || brands[item.toUpperCase()].length === 0}
                        >
                            {item}
                        </NavItem>
                    ))}
                </NavRow>
                <ItemsSection>
                    <SectionSeparator>
                        <h2>{currentItem}</h2>
                        <hr />
                    </SectionSeparator>
                    <ContentGrid>
                        {brands &&
                            brands[currentItem.toUpperCase()] &&
                            brands[currentItem.toUpperCase()].map(
                                (item, index) =>
                                    item.name.toLowerCase() !== "repetit" && (
                                        <BrandLink
                                            href={`/marcas${subCategory ? "/" + subCategory : ""}/${item.slug}`}
                                            key={`brandLink_${index}`}
                                        >
                                            {item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()}
                                        </BrandLink>
                                    )
                            )}
                    </ContentGrid>
                </ItemsSection>
            </Flex>
            <Footer>
                Rua Andréa Paulinetti, 53 - CEP 04707-050, Brooklin, São Paulo/SP
                <br />
                Quer desapegar de uma marca que ainda não está na lista? Entre em contato:{" "}
                <a href="mailto:equipe@repetit.com.br">equipe@repetit.com.br</a>
            </Footer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #f9fafa;
    width: 100%;
    height: 100%;
    align-self: flex-start;
    flex: 1;
    flex-grow: 1;
`;

const NavRow = styled.div`
    background-color: #f3f5f5;
    height: auto;
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;

    ${isMobileMediaQuery} {
        justify-content: space-evenly;
    }
`;

const NavItem = styled.button`
    background-color: transparent;
    border: none;
    padding: 17px 10px 15px 10px;
    margin: 0;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    line-height: 0.92;
    text-align: left;
    color: ${({ active }) => (active ? "#e4858c" : "#34343b")};
    cursor: pointer;

    :hover {
        color: #e4858c;
    }
    :first-child {
        padding-left: 35px;
    }
    :last-child {
        padding-right: 35px;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.4;
            pointer-events: none;
        `};

    ${isMobileMediaQuery} {
        font-size: 10px;
        letter-spacing: 1.8px;
        text-align: center;
        color: #34343c;
        padding-left: 0px;
        padding-right: 0px;

        :first-child {
            padding-left: 15px;
        }
        :last-child {
            padding-right: 15px;
        }
    }
`;

const ItemsSection = styled.div`
    flex: 1;
    align-self: stretch;
    padding: 35px 35px 35px 35px;
    box-sizing: border-box;
`;

const SectionSeparator = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    & > h2 {
        font-family: "DMSerifDisplay";
        font-size: 41px;
        font-weight: normal;
        font-stretch: normal;
        text-transform: uppercase;
        font-style: italic;
        letter-spacing: normal;
        text-align: left;
        line-height: 0.7;
        color: #34343b;
        margin: 0;
        padding: 0;
        margin-right: 10px;
    }
    & > hr {
        border: none;
        border-top: 1px solid #cacad1;
        width: 100%;
        margin: auto;
    }
`;

const ContentGrid = styled.div`
    display: grid;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: fill-available;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-top: 10px;

    ${isMobileMediaQuery} {
        grid-template-columns: repeat(2, 1fr);
        max-height: initial;
    }
`;

const BrandLink = styled.a`
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;

    font-family: "Graphik";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: 0.28px;
    text-align: left;
    color: #676777;
    cursor: pointer;

    :hover {
        color: #e4858c;
    }
`;

const Footer = styled.div`
    margin-top: auto;
    border-top: 1px solid #e2e2e5;
    padding: 20px 34px;

    font-family: "Graphik";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #676777;

    a {
        color: #676777;
        text-decoration: underline;
    }
`;

export default BrandsGrid;
