import React, { useState, useEffect } from "react";
import {useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import moment from "moment";
import api from "../../services/api";
import { openPrivacyNTerms } from "../../helpers";
import { Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import Button from "../../components/Button";
import HorizontalProductItem from "../../components/HorizontalProductItem";
import SectionTitle from "../../components/SectionTitle";
import PedidosHero from "../../assets/images/account-pedidos-vazio.png";
import CloseIcon from "../../assets/images/close.svg";
import CheckBox from "../../components/Inputs/CheckBox";
import BoxIcon from "../../assets/images/box-icon.png";
import OrderIcon from "../../assets/images/order-icon.webp";
import ButtonLink from "../../components/ButtonLink";
import LoadingSpinner from "../../components/LoadingSpinner";
import PromoModal from "../../components/PromoModal";

const MeusPedidos = ({ history, item_id, setModal }) => {
    const order_status = {
        created: "Criado",
        paying: "Processando pagamento",
        preparing_shipping: "Em separação",
        shipping_delivery: "Enviado",
        done: "Entregue",
        canceled: "Cancelado",
        failed: "Falha no pagamento",
    };

    const [pageState, setPageState] = useState("home");
    const [orders, setOrders] = useState(undefined);
    const [currentOrder, setCurrentOrder] = useState(undefined);
    const [returnProduct, setReturnProduct] = useState(undefined);
    const [showReturnProductModal, setShowReturnProductModal] = useState(false);
    const [values, setValues] = useState({});
    const [showPromoModal, setShowPromoModal] = useState(false);
    const [items, setItems] = useState(undefined);
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
        loadOrders();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (
            location &&
            history.pathname === "/minha-conta/pedidos" &&
            orders &&
            orders.items.length > 0 &&
            !item_id
        ) {
            handleListOrders();
        }

        // eslint-disable-next-line
    }, [location && history.pathname]);

    useEffect(() => {
        if (orders && orders.items && orders.items.length > 0) {
            const orderIndex = orders.items.findIndex(
                (item) =>
                    item && item_id && item.id.toString() === item_id.toString()
            );

            if (orderIndex === currentOrder) return;

            if (item_id && orderIndex > -1) {
                handleShowOrder(
                    orders.items.findIndex(
                        (item) => item.id.toString() === item_id.toString()
                    )
                );
            } else if (item_id) {
                navigate("/minha-conta/pedidos");
            }
        }

        // eslint-disable-next-line
    }, [orders, currentOrder]);

    const loadItems = async () => {
        setItems(null);

        const response = await api
            .get(`/profiles/entries/items`)
            .catch((err) => err.response);

        if (response.status === 200) {
            setItems({
                ...response.data,
            });
        } else {
            setItems({
                items: [],
                pagination: {},
            });
        }
    };

    const handleChange = (e) => {
        let {
            target: { name, value, checked, type },
        } = e;

        if (name !== undefined) {
            if (
                value !== undefined &&
                (checked === undefined || type === "radio")
            ) {
                setValues({
                    ...values,
                    [name]: value,
                });
            } else if (checked !== undefined) {
                setValues({
                    ...values,
                    [name]: checked,
                });
            }
        }
    };

    const loadOrders = async () => {
        let response = await api.get(`/profiles/orders`);

        if (response.status === 200) {
            setOrders({
                ...response.data,
            });
        } else {
            setOrders({
                items: [],
                pagination: {},
            });
        }
    };

    const handleLoadMoreOrders = async () => {
        let response = await api.get(
            orders.pagination.next_page_path.replace("/v1/", "")
        );

        const scrollY = window.scrollY;

        if (response.status === 200) {
            setOrders({
                ...orders,
                items: [...orders.items, ...response.data.items],
                pagination: response.data.pagination,
                scrollY,
            });
        }
    };

    const handleLoadMoreProducts = async () => {
        if (!orders.items[currentOrder]) return;

        let response = await api.get(
            orders.items[currentOrder].pagination.next_page_path.replace(
                "/v1/",
                ""
            )
        );

        const scrollY = window.scrollY;

        if (response.status === 200) {
            let newOrders = { ...orders };

            newOrders.items[currentOrder] = {
                ...newOrders.items[currentOrder],
                items: [
                    ...newOrders.items[currentOrder].items,
                    ...response.data.items,
                ],
                pagination: response.data.pagination,
                scrollY,
            };

            setOrders({
                ...orders,
                items: [...orders.items, ...response.data.items],
                pagination: response.data.pagination,
                scrollY,
            });
        }
    };

    const handleListOrders = () => {
        setCurrentOrder(undefined);
        setPageState("list_orders");
        navigate("/minha-conta/pedidos");
    };

    const handleShowOrder = async (order_index) => {
        setCurrentOrder(order_index);

        let response = await api
            .get(`/profiles/orders/${orders.items[order_index].id}/items`)
            .catch((err) => err.response);

        let newOrders = { ...orders };

        if (response && response.status === 200) {
            if (
                window.location.pathname !==
                `/minha-conta/pedidos/${orders.items[order_index].id}`
            )
                navigate(
                    `/minha-conta/pedidos/${orders.items[order_index].id}`
                );

            newOrders.items[order_index] = {
                ...newOrders.items[order_index],
                ...response.data,
            };
        } else {
            newOrders.items[order_index] = {
                ...newOrders.items[order_index],
                items: [],
                pagination: {},
            };
        }

        setOrders(newOrders);

        setPageState("show_order");
    };

    const handleReturnProduct = (item) => {
        setReturnProduct(item);
        setShowReturnProductModal(!showReturnProductModal);
    };

    const handleSubmitProductReturn = () => {
        setShowReturnProductModal(false);
        window.open(
            "https://api.whatsapp.com/send?phone=5511990085518",
            "_blank"
        );
    };

    if (["home", undefined].includes(pageState) && orders === undefined)
        return (
            <>
                <SectionTitle margin="0 0 36px 0">Carregando</SectionTitle>
                <LoadingSpinner dark size={15} />
            </>
        );

    return !orders ? null : (
        <>
            {orders.items.length === 0 && (
                <Flex
                    width="100%"
                    margin={
                        isMobile ? "0px auto 0 auto" : "33px auto 77px auto"
                    }
                    direction={isMobile ? "column" : "row"}
                >
                    <img
                        src={PedidosHero}
                        height={isMobile ? 320 : undefined}
                        alt="Menina pulando feliz"
                    />

                    <Flex
                        width={isMobile ? "100%" : undefined}
                        flex="1"
                        order={isMobile ? "-1" : "1"}
                        alignItems={isMobile ? "center" : "flex-start"}
                        direction="column"
                        margin={isMobile ? "0 auto" : "auto 0 auto 26px"}
                    >
                        <Flex width="100%" mobile>
                            <SectionTitle margin="0 0 29px 0">
                                Você ainda não
                                <br />
                                tem pedidos
                            </SectionTitle>
                        </Flex>

                        <Flex justifyContent="flex-start" width="100%" desktop>
                            <Title>
                                Você ainda não
                                <br />
                                tem pedidos
                            </Title>
                        </Flex>

                        <Flex desktop>
                            <PinkSeparator />
                        </Flex>

                        <Text>
                            Que tal conhecer nossas curadoria exclusiva? São
                            peças das marcas mais desejadas por menos.
                            Garimpadas com carinho para o seu filho e para
                            preservar os recursos do planeta.
                        </Text>

                        <Flex margin={isMobile ? "0 0 30px 0" : undefined}>
                            <PinkSeparator />
                        </Flex>

                        <Flex desktop>
                            <Button
                                width="280px"
                                margin="34px 0 0 0"
                                dark
                                filled
                                value="confira as peças"
                                onClick={() => navigate("/produtos")}
                            />
                        </Flex>
                    </Flex>

                    <Flex mobile>
                        <Button
                            width="280px"
                            margin="29px 0 0 0"
                            dark
                            filled
                            value="confira as peças"
                            onClick={() => navigate("/produtos")}
                        />
                    </Flex>
                </Flex>
            )}

            {((orders.items.length > 0 && pageState === "home") ||
                pageState === "list_orders") && (
                <>
                    <SectionTitle margin="0 0 36px 0">
                        Meus Pedidos
                    </SectionTitle>

                    <OrdersGrid>
                        {orders.items &&
                            orders.items.map((order, index) => (
                                <OrderCard
                                    key={order.id}
                                    onClick={() => handleShowOrder(index)}
                                >
                                    <Flex desktop>
                                        <h1>
                                            {order.kind === "B"
                                                ? `Box ${order.id}`
                                                : `Compra`}
                                        </h1>
                                    </Flex>

                                    <img
                                        src={
                                            order.kind === "B"
                                                ? BoxIcon
                                                : OrderIcon
                                        }
                                        alt="Lote"
                                    />

                                    <hr />

                                    <Flex
                                        alignItems="center"
                                        direction="column"
                                    >
                                        <Flex mobile>
                                            <h1>
                                                {order.kind === "B"
                                                    ? `Box ${order.id}`
                                                    : `Unitário`}
                                            </h1>
                                        </Flex>

                                        <OrderName>
                                            {order.customer_child
                                                ? order.customer_child.name.toLowerCase()
                                                : `Pedido ${order.id}`}
                                        </OrderName>

                                        <p>
                                            Status: {order_status[order.status]}
                                        </p>

                                        <p>
                                            Pedido em:{" "}
                                            {moment(order.date).format(
                                                "DD/MM/YYYY"
                                            )}
                                        </p>
                                    </Flex>
                                </OrderCard>
                            ))}
                    </OrdersGrid>

                    {orders.pagination && orders.pagination.next_page_path && (
                        <Button
                            width="280px"
                            margin="0 auto 34px auto"
                            filled
                            dark
                            value="carregar mais pedidos"
                            onClick={handleLoadMoreOrders}
                        />
                    )}
                </>
            )}

            {pageState === "show_order" && currentOrder !== undefined && (
                <>
                    <SectionTitle margin="0">
                        Histórico dos pedidos
                    </SectionTitle>

                    <Flex margin="10px 0">
                        <Text>
                            Aqui você acompanha o status do seu pedido e confere
                            o histórico das compras anteriores.
                        </Text>
                    </Flex>

                    <Separator />

                    <Flex
                        width="100%"
                        justifyContent="flex-start"
                        direction="column"
                        padding={isMobile ? "0px 20px" : undefined}
                        boxSizing="border-box"
                    >
                        {orders.items[currentOrder].items &&
                            orders.items[currentOrder].items.map(
                                (item, index) => (
                                    <HorizontalProductItem
                                        key={index}
                                        item={{
                                            ...item,
                                            price: parseFloat(item.price_label),
                                            status:
                                                item.sale_item &&
                                                item.sale_item.status,
                                            date: orders.items[currentOrder]
                                                .date,
                                        }}
                                        box
                                        sold
                                        returnProductAction={() =>
                                            handleReturnProduct(item)
                                        }
                                    />
                                )
                            )}
                    </Flex>

                    <Separator />

                    <Flex
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        margin="0 auto 10px 0"
                    >
                        <Text>
                            Após o recebimento você tem até 7 dias corridos para efetuar a devolução da peça.{" "}
                            <a href="https://api.whatsapp.com/send?phone=5511990085518">
                                Entre em contato
                            </a>
                        </Text>
                    </Flex>

                    <Flex margin="0 auto 34px auto">
                        {orders.items[currentOrder].pagination &&
                            orders.items[currentOrder].pagination
                                .next_page_path && (
                                <Button
                                    width="280px"
                                    margin="0 35px 0 auto"
                                    filled
                                    dark
                                    value="carregar mais produtos"
                                    onClick={handleLoadMoreProducts}
                                />
                            )}
                        <Button
                            width="280px"
                            margin="0 auto 0 0"
                            dark
                            value="voltar"
                            onClick={handleListOrders}
                        />
                    </Flex>
                </>
            )}

            {returnProduct && showReturnProductModal && (
                <ReturnProductModal>
                    <Backdrop />
                    <Modal>
                        <Flex width="100%" alignItems="flex-start">
                            <ModalTitle>Solicitação de devolução</ModalTitle>
                            <CloseButton
                                onClick={() => setShowReturnProductModal(false)}
                            >
                                <img
                                    src={CloseIcon}
                                    height="18px"
                                    alt="Fechar"
                                />
                            </CloseButton>
                        </Flex>
                        <ModalSeparator full />

                        <HorizontalProductItem
                            item={returnProduct}
                            box
                            sold
                            hideStatus
                        />

                        <Flex>
                            <Button
                                width="240px"
                                margin="0 27px 0 0"
                                dark
                                filled
                                value="Solicitar devolução"
                                disabled={!values.accept}
                                onClick={handleSubmitProductReturn}
                            />
                            {/* <Button
                                padding="0"
                                margin="0"
                                dark
                                link
                                value="Saiba mais"
                            /> */}
                        </Flex>

                        <CheckBox
                            name="accept"
                            margin="36px auto 0 0"
                            onChange={handleChange}
                        >
                            <span>
                                Eu li e aceito os{" "}
                                <ButtonLink
                                    onClick={() => openPrivacyNTerms("terms")}
                                >
                                    Termos e Condições
                                </ButtonLink>{" "}
                                e{" "}
                                <ButtonLink
                                    onClick={() => openPrivacyNTerms("privacy")}
                                >
                                    Política de Privacidade
                                </ButtonLink>
                            </span>
                        </CheckBox>
                    </Modal>
                </ReturnProductModal>
            )}

            {items?.items && values.promotedItems && (
                <PromoModal
                    items={items.items}
                    promotedItems={values.promotedItems}
                    show={showPromoModal}
                    setModal={setModal}
                    callback={(status) => setShowPromoModal(status)}
                    successCallback={() => loadItems()}
                />
            )}
        </>
    );
};
const Title = styled.h1`
    text-transform: uppercase;
    margin: 0 0 22px 0;

    font-family: "SackersGothicStd";
    font-size: 20px;
    font-weight: 900;
    line-height: 1.3;
    letter-spacing: 4px;
    color: #34343b;
`;

const Separator = styled.hr`
    margin: 0 0 30px 0;
    border: none;
    width: 100%;
    height: 1px;
    background-color: #cacad1;
`;

const PinkSeparator = styled.hr`
    margin: 0;
    border: none;
    width: 40px;
    height: 3px;
    background-color: #e4858c;
`;

const Text = styled.p`
    margin: 18px 0 21px 0;
    font-size: 15px;
    line-height: 1.4;
    color: #676777;

    a {
        color: #676777;
        font-weight: 600;
    }

    ${isMobileMediaQuery} {
        text-align: center;
        max-width: 310px;
    }
`;

const ReturnProductModal = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    ${isMobileMediaQuery} {
        padding: 90px 0px;
        align-items: baseline;
        max-height: 100vh;
        overflow-y: auto;
    }
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.7);
`;

const Modal = styled.div`
    position: relative;
    width: 600px;
    min-height: 386px;
    max-width: 90vw;
    max-height: 80vh;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 0 rgba(52, 52, 59, 0.15);
    padding: 28px 30px 30px 30px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    ${isMobileMediaQuery} {
        padding: 38px 20px 28px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        max-width: 90vw;
        max-height: initial;
        height: auto;
    }
`;

const ModalTitle = styled.h2`
    text-transform: uppercase;
    margin: 0 auto 0 0;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 2.2px;
    text-align: left;
    color: #34343b;
`;

const CloseButton = styled.button`
    border: none;
    background: transparent;
    position: absolute;
    top: 26px;
    right: 19px;
    height: 18px;
    width: 18px;
    padding: 0px;
    cursor: pointer;
    z-index: 20;
`;

const ModalSeparator = styled.hr`
    margin-top: 18px;
    margin-bottom: 30px;
    width: 100%;
    height: 1px;
    border: none;
    background-color: #f4f4f5;

    ${(props) =>
        props.full &&
        css`
            width: calc(100% + 60px);
            margin-left: -30px;
        `}
`;

const OrdersGrid = styled.div`
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 36px;
    grid-row-gap: 36px;
    margin-bottom: 57px;

    ${isMobileMediaQuery} {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 21px;
    }
`;

const OrderCard = styled(Flex)`
    position: relative;
    width: 279px;
    height: 390px;
    padding: 0px;
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);
    justify-content: flex-start;
    flex-direction: column;
    background-color: #fbfcfc;
    box-sizing: border-box;
    flex-wrap: nowrap;

    label:first-child {
        position: absolute;
        top: 8px;
        right: 8px;
    }

    h1 {
        text-transform: capitalize;
        margin: 50px auto 40px auto;

        font-family: "DMSerifDisplay";
        font-size: 36px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.67;
        letter-spacing: normal;
        text-align: center;
        color: #34343b;
    }

    img {
        width: 140px;
        height: 129px;
        object-fit: contain;
    }

    hr {
        width: 79px;
        height: 1px;
        min-height: 1px;
        margin: 0px auto;
        border: none;
        background-color: #313139;

        margin-top: 40px;
        margin-bottom: 15px;
    }

    p {
        margin: 0px;
        font-family: "Graphik";
        font-size: 15px;
        line-height: 1.07;
        text-align: center;
        color: #676777;
        margin-bottom: 12px;
    }

    ${isMobileMediaQuery} {
        width: 100%;
        max-width: 320px;
        height: auto;
        min-height: 170px;
        flex-direction: row;
        margin: 0 auto;
        padding: 42px 20px;

        h1 {
            margin: 0;
            font-size: 28px;
            line-height: 20px;
            text-align: left;
            margin-bottom: 13px;
        }

        h2 {
            margin: 0;
            font-size: 19px;
            line-height: 14px;
            text-align: left;
            margin-bottom: 9px;
        }

        img {
            width: 90px;
            height: 83px;
            object-fit: contain;
        }

        hr {
            width: 1px;
            min-width: 1px;
            min-height: 79px;
            margin: auto 20px auto 26px;
        }

        p {
            font-size: 13px;
            text-align: left;
        }
    }
`;

const OrderName = styled.h2`
    margin: 0px auto 12px auto;
    text-transform: capitalize;

    font-family: "DMSerifDisplay";
    font-size: 19px;
    font-style: italic;
    font-weight: normal;
    line-height: 1.11;
    text-align: center;
    color: #34343b;
`;

export default MeusPedidos;
