import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import styled, { css } from "styled-components";
import ReactTooltip from "react-tooltip";
import api from "../../services/api";
import { capitalize } from "../../helpers";
import { Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import { generateUUID } from "../../components/Functions";
import * as CartActions from "../../redux/actions/cart";
import { ReactComponent as Seta } from "../../assets/images/seta.svg";
import ShareIcon from "./../../assets/images/share-icon.png";
import Header from "./../../components/Header";
import Footer from "../../components/Footer";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import SectionTitle from "../../components/SectionTitle";
import ProductCarroussel from "../../components/ProductCarroussel";
import MobileImagesCarroussel from "../../components/ProductModal/MobileImagesCarroussel";
import AlertModal from "../../components/AlertModal";

const Product = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { product_slug } = useParams();
    const conservation_status = {
        brand_new: "Nunca usado",
        excelent: "Excelente",
        good: "Bom",
        used: "Usado",
    };
    const [product, setProduct] = useState(undefined);
    const [currentImage, setCurrentImage] = useState(undefined);
    const [modal, setModal] = useState({});
    const user = useSelector((state) => state.user);
    const selectorProducts = useSelector((state) => state.cart.products) ?? [];
    const [setIP] = useState("");
    const getData = async () => {
        const res = await axios.get("https://geolocation-db.com/json/");
        if (setIP) setIP(res.data.IPv4);
    };

    useEffect(() => {
        //passing getData method to the lifecycle method
        getData();
    }, []);

    useEffect(() => {
        setProduct(undefined);
        setCurrentImage(undefined);
        loadProduct();
        // eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        product && product.item.images && product.item.images.length > 0 && setCurrentImage(product.item.images[0]);
        if (product) {
            let relatedProductsDataLayer = [];
            product.related_items.map((item, index) =>
                relatedProductsDataLayer.push({
                    name: item.name,
                    id: item.id,
                    price: item.price_label,
                    brand: item.brand.name,
                    category: item.item_category.name,
                    position: index + 1,
                    list: `Product_related_items`,
                })
            );
            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });

            // eslint-disable-next-line
            dataLayer.push({
                event: "productImpression",
                ecommerce: {
                    currencyCode: "BRL",
                    impressions: relatedProductsDataLayer,
                },
            });
        }
        // eslint-disable-next-line
        console.log(product)
    }, [product]);

    const loadProduct = async () => {
        let response = await api.get(`/items/${product_slug}`).catch((err) => err.response);

        if (response.status === 200) {
            // eslint-disable-next-line
            let userSessionServerData = window.userCurrentServerData(user);
            let event_id = generateUUID();
            let event_time = Math.round(Date.now() / 1000);

            window.fbq(
                "trackCustom",
                "ViewProduct",
                {
                    content_category: response.data.item.item_category.name || "",
                    content_name: response.data.item.name || "",
                    content_type: "product",
                    value: response.data.item.price_label || "",
                },
                { eventID: event_id }
            );

            await api
                .post("/facebook_ads_event", {
                    data: [
                        {
                            event_name: "ViewProduct",
                            event_id: event_id,
                            event_time: event_time,
                            event_source_url: window.location.href,
                            action_source: "website",
                            user_data: userSessionServerData,
                            custom_data: {
                                content_category: response.data.item.item_category.name || "",
                                content_name: response.data.item.name || "",
                                content_type: "product",
                                value: response.data.item.price_label || "",
                            },
                        },
                    ],
                })
                .then((res) => console.log("EVENTO ViewProduct", res.data))
                .catch((res) => res.response);

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({
                ecommerce: {
                    detail: {
                        products: [response.data],
                    },
                },
            });

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({
                event: "productDetailView",
                ecommerce: {
                    detail: {
                        actionField: { list: "Interna Camisetas" }, //Nome da lista
                        products: [
                            {
                                name: response.data.item.name, // Nome do produto
                                id: response.data.item.id, // Id ou SKU do produto
                                price: response.data.item.price_label, // Preço do produto*
                                brand: response.data.item.brand.name, // Marca do produto
                                category: response.data.item.item_category.name, // Categoria do produto
                            },
                        ],
                    },
                },
            });

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({
                event: "virtualPageview",
                page: response.data.item.item_category.name + " " + response.data.item.brand.name,
                type: "Produto",
                environment: process.env.REACT_APP_ENV,
                user: [
                    {
                        name: user.first_name && user.last_name ? user.first_name + " " + user.last_name : "",
                        username: user.slug ? user.slug : "",
                        user_id: user.id ? user.id : "",
                        email: user.email ? user.email : "",
                        language: "pt-br",
                        returning: JSON.parse(localStorage.getItem("accepted_cookies")) ? true : false,
                    },
                ],
            });

            setProduct({ ...response.data });
        } else {
            setProduct(undefined);

            setModal({
                show: true,
                message: (
                    <>
                        O produto que você tentou acessar não existe ou já foi vendido.
                        <br />
                        <Button
                            onClick={() => navigate("/produtos")}
                            filled
                            dark
                            borderradius
                            value="ver mais produtos"
                        />
                    </>
                ),
            });
        }
    };

    const handleAddToCart = () => {
        if (!selectorProducts.some((item) => item.id === product.item.id)) {
            dispatch(CartActions.addProduct(product.item));
            selectorProducts.push(product.item);
            if (user.token) {
                saveAbandonedCart(selectorProducts.map((product) => product.id));
            }
            let userSessionServerData = window.userCurrentServerData(user);
            let event_id = generateUUID();
            let event_time = Math.round(Date.now() / 1000);
            // eslint-disable-next-line

            window.fbq(
                "track",
                "AddToCart",
                {
                    content_ids: [product.item.id],
                    value: product.item.price_label,
                    currency: "BRL",
                    content_category: product.item.item_category.name,
                    content_name: product.item.name,
                    content_type: "product",
                },
                { eventID: event_id }
            );

            api.post("/facebook_ads_event", {
                data: [
                    {
                        event_name: "AddToCart",
                        event_id: event_id,
                        event_time: event_time,
                        event_source_url: window.location.href,
                        action_source: "website",
                        user_data: userSessionServerData,
                        custom_data: {
                            content_ids: [product.item.id],
                            value: product.item.price_label,
                            currency: "BRL",
                            content_category: product.item.item_category.name,
                            content_name: product.item.name,
                            content_type: "product",
                        },
                    },
                ],
            })
                .then((res) => console.log("EVENTO AddToCart", res.data))
                .catch((res) => console.log("ERROR: ", res.response));

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({
                event: "addToCart",
                ecommerce: {
                    currencyCode: "BRL",
                    add: {
                        products: [
                            {
                                name: product.item.name,
                                id: product.item.id,
                                price: product.item.price_label,
                                brand: product.item.brand.name,
                                category: product.item.item_category.name,
                                quantity: 1,
                            },
                        ],
                    },
                },
            });
        }
        if (isMobile) navigate("/carrinho");
    };

    const handleRemoveToCart = () => {
        dispatch(CartActions.removeProduct(product.item.id));
        if (user.token) {
            const productsTemp = selectorProducts.filter((product) => product.id !== product.item.id);
            saveAbandonedCart(productsTemp.map((product) => product.id));
        }
        if (isMobile) navigate("/carrinho");
    };

    const saveAbandonedCart = async (idsProducts) => {
        try {
            let response = await api.post("/carts", {
                item_ids: idsProducts,
            });
            if (response.status === 204) {
                console.log("saveAbandonedCart: /carts");
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const handleMousePan = (e) => {
        var bounds = e.target.getBoundingClientRect();
        var x = e.clientX - bounds.left;
        var y = e.clientY - bounds.top;

        let img = e.target.childNodes[0];

        img.style.position = "absolute";

        let xPercent = x / (img.width / 100);
        let yPercent = y / (img.height / 100);

        img.style.transform = `scale(2) translate(${(50 - xPercent) / 1.5}%, ${(50 - yPercent) / 1.5}%)`;

        if (img && img.src && img.src.indexOf("?") > 0) {
            img.src = img.src.substring(0, img.src.indexOf("?")) + "?w=1000&bg=FFFFFF&auto=format,compress";
        }
    };

    const handleResetZoom = (e) => {
        let img = e.target.childNodes[0];

        img.style.transform = "";

        img.src =
            img.src.substring(0, img.src.indexOf("?") > 0 ? img.src.indexOf("?") : img.src.length) + "?w=361&h=397";
    };

    const userCity = !user.id ? !user.id
        : user.user_addresses && user.user_addresses.length > 0 && user.user_addresses[0].city === "São Paulo";

    return (
        <>
            <Header
                navigationItems={[
                    { label: "Compre", action: () => navigate("/categorias/novidades") },
                    { label: "Quero vender", action: () => navigate("/desapegue") },
                    { label: "Peça seu box", action: () => navigate("/box") },
                    { label: "Lojas Influencers", action: () => navigate("/lojas-influencers") },
                    { label: "Blog", action: () => navigate("/blog") },
                    { label: "Outlet", action: () => navigate("/outlet") },
                    { label: "Sale", action: () => navigate("/promocoes") },
                ]}
                append={
                    <Flex
                        flex="1"
                        width="100%"
                        height="50px"
                        margin="0"
                        justifyContent="flex-start"
                        direction="column"
                        background="#313139"
                        position="sticky"
                        zIndex="1"
                    >
                        <Flex flex="1" width={isMobile ? "auto" : "1222px"} margin="auto" justifyContent="flex-start">
                            <BreadCrumbItem as={Link} to="/home">
                                Home
                            </BreadCrumbItem>
                            <ChevronRight />
                            <BreadCrumbItem as={Link} to="/produtos">
                                Produtos
                            </BreadCrumbItem>
                            <ChevronRight />
                            {product && product.item && product.item.item_category && (
                                <>
                                    <BreadCrumbItem
                                        as={Link}
                                        to={`/produtos?search=${encodeURIComponent(
                                            product.item.item_category.name.toLowerCase()
                                        )}`}
                                    >
                                        {capitalize(product.item.item_category.name.toLowerCase(), true)}
                                    </BreadCrumbItem>
                                    <ChevronRight />
                                    <BreadCrumbItem active>Características</BreadCrumbItem>
                                </>
                            )}
                        </Flex>
                    </Flex>
                }
            />
            {product === undefined ? (
                <Loading />
            ) : (
                <Container>
                    <Helmet>
                        <title>
                            {capitalize(product.item.item_category.name.toLowerCase())}{" "}
                            {capitalize(product.item.brand.name.toLowerCase())}
                        </title>
                        <meta property="og:url" content={window.location.href} />
                    </Helmet>
                    <Flex
                        direction="column"
                        width={isMobile ? "100%" : "908px"}
                        margin={isMobile ? "70px auto 0px auto" : "100px auto 0px auto"}
                    >
                        <Flex width="100%" alignItems={isMobile && "flex-start"} direction={isMobile && "column"}>
                            <Flex direction="column" margin="auto 20px auto 0" desktop>
                                {product.item.images &&
                                    product.item.images.map((image, index) => (
                                        <PreviewBox
                                            key={`image_${index}`}
                                            onMouseEnter={() => setCurrentImage(image)}
                                            image={image}
                                            active={currentImage === image}
                                        />
                                    ))}
                            </Flex>

                            <Flex margin="0 auto" direction="column" mobile>
                                <ProductTitle>{product.item.name}</ProductTitle>
                                {product.item.partner_store && (
                                    <PartnerTitle>
                                        Vendido por loja parceira
                                        <ReactTooltip style={{ width: "256px" }} />
                                        <span
                                            style={{
                                                marginLeft: "6px",
                                            }}
                                            data-tip={
                                                "Produto de loja parceira com curadoria e entrega realizados por terceiros."
                                            }
                                        >
                                            <QuestionContainer>?</QuestionContainer>
                                        </span>
                                    </PartnerTitle>
                                )}

                                <ProductPrice oldPrice={!!product.item.original_price_label}>
                                    R${" "}
                                    {(!!product.item.original_price_label
                                        ? product.item.original_price_label || product.item.price_label
                                        : product.item.price_label
                                    ).toLocaleString("pt-br", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </ProductPrice>
                                {!!product.item.original_price_label && (
                                    <ProductPrice promoPrice>
                                        R${" "}
                                        {product.item.price_label.toLocaleString("pt-br", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </ProductPrice>
                                )}
                                <ProductCode>Cod.: {product.item.id.toString()}</ProductCode>
                                {product.item.seller_slug && (
                                    <Flex margin="0 0 12px 0">
                                        <PartnerLink href={`/loja/${product.item.seller_slug}`}>
                                            Ver mais peças do vendedor
                                        </PartnerLink>
                                    </Flex>
                                )}
                                <Flex margin="0 0 12px 0">
                                    <PartnerLink href={`/marcas/${product.item.brand.slug}`}>
                                        {`${product.item.brand.name}`}
                                    </PartnerLink>
                                </Flex>
                            </Flex>

                            <Flex
                                height="100%"
                                direction="column"
                                margin={isMobile ? "0 auto 15px auto" : "auto 35px auto 0"}
                                padding={isMobile && "0px 20px"}
                                width={isMobile && "100%"}
                                boxSizing={isMobile && "border-box"}
                            >
                                <Flex direction="column" width="100%" flex="1">
                                    <ImageBox
                                        onMouseMove={!isMobile && handleMousePan}
                                        onMouseLeave={!isMobile && handleResetZoom}
                                    >
                                        {!isMobile ? (
                                            <img src={currentImage} alt={product.item.name} />
                                        ) : (
                                            <MobileImagesCarroussel images={product.item.images} />
                                        )}

                                        {product.item.conservation === "used" && <NeverUsed>Usado</NeverUsed>}

                                        {product.item.original_price_label && (
                                            <SaleTag promo>
                                                <span>SALE</span>
                                            </SaleTag>
                                        )}
                                    </ImageBox>

                                    {userCity && product.item.eligible_for_free_shipping && (
                                        <FreeShipping>
                                            <FreeShippingSpan>FRETE GRÁTIS SP DISPONÍVEL</FreeShippingSpan>
                                        </FreeShipping>
                                    )}

                                    {navigator && navigator.share !== undefined && (
                                        <ShareRow
                                            width={isMobile ? "100%" : "398px"}
                                            background="#F3F5F5"
                                            justifyContent="flex-start"
                                            padding="15px 0px 12px 21px"
                                        >
                                            Gostou? Compartilhe:
                                            <img
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    if (navigator && navigator.share !== undefined && navigator.share) {
                                                        navigator
                                                            .share({
                                                                title:
                                                                    product.item.item_category.name.toLowerCase() +
                                                                    " " +
                                                                    product.item.brand.name.toLowerCase(),

                                                                url:
                                                                    window.location.origin +
                                                                    "/produto/" +
                                                                    product.item.slug,
                                                            })
                                                            .catch((error) => console.log("Error sharing", error));
                                                    } else {
                                                        console.log(
                                                            "Share not supported on this browser, do it the old way."
                                                        );
                                                    }
                                                }}
                                                height="29px"
                                                src={ShareIcon}
                                                alt="Compartilhar"
                                            />
                                        </ShareRow>
                                    )}
                                </Flex>
                            </Flex>

                            <Flex
                                width="100%"
                                height="100%"
                                flex="1"
                                direction="column"
                                alignItems="flex-start"
                                justifyContent="space-between"
                                margin={isMobile ? "0 0 auto 0" : "0 0 auto 0"}
                                padding="0 20px"
                                boxSizing="border-box"
                                maxWidth={!isMobile && "355px"}
                            >
                                <Flex direction="column" alignItems="flex-start" justifyContent="flex-start" desktop>
                                    <ProductTitle>{product.item.name}</ProductTitle>

                                    {product.item.partner_store && (
                                        <PartnerTitle>
                                            Vendido por loja parceira
                                            <ReactTooltip style={{ width: "256px" }} />
                                            <span
                                                style={{
                                                    marginLeft: "2px",
                                                }}
                                                data-tip={
                                                    "Produto de loja parceira com curadoria e entrega realizados por terceiros."
                                                }
                                            >
                                                <QuestionContainer>?</QuestionContainer>
                                            </span>
                                        </PartnerTitle>
                                    )}

                                    <ProductPrice oldPrice={!!product.item.original_price_label}>
                                        R${" "}
                                        {(!!product.item.original_price_label
                                            ? product.item.original_price_label || product.item.price_label
                                            : product.item.price_label
                                        ).toLocaleString("pt-br", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </ProductPrice>
                                    {!!product.item.original_price_label && (
                                        <ProductPrice promoPrice>
                                            R${" "}
                                            {product.item.price_label.toLocaleString("pt-br", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </ProductPrice>
                                    )}
                                    <ProductCode>Cod.: {product.item.id.toString()}</ProductCode>
                                    {product.item.seller_slug && (
                                        <Flex margin="0 0 12px 0">
                                            <PartnerLink href={`/loja/${product.item.seller_slug}`}>
                                                Ver mais peças do vendedor
                                            </PartnerLink>
                                        </Flex>
                                    )}
                                    <Flex margin="0 0 12px 0">
                                        <PartnerLink href={`/marcas/${product.item.brand.slug}`}>
                                            {`${product.item.brand.name}`}
                                        </PartnerLink>
                                    </Flex>
                                </Flex>

                                {!selectorProducts.some((item) => item.id === product.item.id) && (
                                    <Button
                                        width={isMobile ? "100%" : "250px"}
                                        dark
                                        filled
                                        borderradius
                                        value={isMobile ? "Adicionar" : "Colocar na Sacola"}
                                        margin={isMobile ? "0px auto 30px auto" : "auto 0 32px 0"}
                                        onClick={handleAddToCart}
                                    />
                                )}

                                {selectorProducts.some((item) => item.id === product.item.id) && (
                                    <Button
                                        width={isMobile ? "100%" : "250px"}
                                        red
                                        filled
                                        borderradius
                                        value={isMobile ? "Remover" : "Remover da Sacola"}
                                        margin={isMobile ? "0px auto 30px auto" : "auto 0 32px 0"}
                                        onClick={handleRemoveToCart}
                                    />
                                )}


                                <ProductDescription>
                                    {product.item.size && (
                                        <>
                                            <strong>Tamanho: </strong>
                                            {product.item.size.toLowerCase()}
                                            <br />
                                        </>
                                    )}
                                    {product.item.gender && (
                                        <>
                                            <strong>Gênero:</strong> {product.item.gender === 'male' ? 'Masculino' : product.item.gender === 'female' ? 'Feminino' : 'Unissex'}
                                            <br />
                                        </>
                                    )}
                                    <strong>Cor: </strong>
                                    {product.item.color_list &&
                                        product.item.color_list.map((item) => item.name.toLowerCase()).join(", ")}
                                    <br />
                                    {product.item.item_design && (
                                        <>
                                            <strong>Estampa: </strong>
                                            {product.item.item_design.name}
                                            <br />
                                        </>
                                    )}
                                    {product.item.brand && (
                                        <>
                                            <strong>Marca: </strong>
                                            {product.item.brand.name}
                                            <br />
                                        </>
                                    )}
                                    {product.item.item_fabric && (
                                        <>
                                            <strong>Composição: </strong>
                                            {product.item.item_fabric.name}
                                            <br />
                                        </>
                                    )}
                                    {product.item.conservation && (
                                        <>
                                            <strong>Estado de conservação: </strong>
                                            {conservation_status[product.item.conservation]}
                                            <br />
                                        </>
                                    )}
                                    {product.item.notes && (
                                        <>
                                            <strong>Observações: </strong>
                                            {product.item.notes}
                                        </>
                                    )}
                                </ProductDescription>
                            </Flex>
                        </Flex>

                        {product.related_items && (
                            <>
                                <SectionTitle margin={isMobile && "40px 0 0 0"}>Você também pode gostar:</SectionTitle>

                                <ProductCarroussel
                                    items={product.related_items || []}
                                    margin={isMobile && "0 0 30px 0"}
                                    locale={`Product_related_items`}
                                />
                            </>
                        )}
                    </Flex>
                </Container>
            )}

            <Footer />

            {modal && (
                <AlertModal
                    {...modal}
                    show={modal.show}
                    title={modal.title}
                    message={modal.message}
                    callback={() => {
                        setModal({ ...modal, show: false });
                        navigate("/produtos");
                    }}
                />
            )}
        </>
    );
};

const Container = styled.div``;

const ImageBox = styled.div`
    position: relative;
    border: solid 1px #f4f4f5;
    background-color: #ffffff;
    height: 397px;
    min-height: 397px;
    max-height: 397px;
    overflow: hidden;
    width: 398px;
    min-width: 100%;
    max-width: 100%;
    flex: 1;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        pointer-events: none;
    }

    ${isMobileMediaQuery} {
        width: 100%;
        height: auto;
        border: none;

        img {
            border: solid 1px #f4f4f5;
        }
    }
`;

const PreviewBox = styled.div`
    border: solid 1px #f4f4f5;
    background-color: #ffffff;
    height: 70px;
    width: 70px;
    cursor: pointer;
    ${(props) => !props.active && `opacity: 0.7;`}

    background-image: url(${(props) => props.image});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    :hover {
        opacity: 1;
    }

    :not(:last-child) {
        margin-bottom: 10px;
    }
`;
const QuestionDropDown = styled.div`
    position: absolute;
    top: calc(100% + 18px);
    left: 50%;
    transform: translateX(-50%);
    width: 320px;
    min-width: 156px;
    padding: 16px;
    border-radius: 4px;
    background-color: #f3f5f5;
    color: #34343c;
    box-shadow: 0px 2px 6px 0 rgba(52, 52, 64, 0.16);
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 5;
    line-height: 1;

    p {
        margin: 0px;

        font-family: "Graphik";
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 17.5px;
        letter-spacing: normal;
        text-align: left;
        color: #8c8ca1;

        :hover,
        &.active {
            color: #e4858c;
        }
    }
`;

const QuestionContainer = styled.div`
    display: inline-flex;
    background-color: transparent;
    border: 1px solid #34343b;
    color: #34343b;
    font-size: 12px;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    line-height: 0;
    vertical-align: middle;
    text-align: center;
    margin-left: 0.2rem;
    cursor: pointer;
    position: relative;

    ${QuestionDropDown} {
        display: none;
    }

    :hover ${QuestionDropDown} {
        display: block;
    }
`;

const ProductTitle = styled.h1`
    margin: 0 0 19px 0;
    text-transform: capitalize;

    font-family: "DMSerifDisplay";
    font-size: 38px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.87;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
    max-width: 280px;

    ${isMobileMediaQuery} {
        text-align: center;
        font-size: 24px;
        max-width: 80%;
    }
`;

const ProductCode = styled.p`
    margin: 0 0 12px 0;
    font-family: "Graphik";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #838383;

    ${isMobileMediaQuery} {
        font-size: 13px;
    }
`;

const ProductPrice = styled.p`
    margin: 0 0 8px 0;
    font-family: "Graphik";
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #838383;

    ${isMobileMediaQuery} {
        font-size: 13px;
    }

    ${({ oldPrice }) =>
        oldPrice &&
        css`
            text-decoration: line-through;
            opacity: 0.9;
            margin-bottom: 8px;
            line-height: 1.25;
        `}

    ${({ promoPrice }) =>
        promoPrice &&
        css`
            font-size: 19px;
            color: #e4858c;

            ${isMobileMediaQuery} {
                font-size: 16px;
            }
        `}
`;

const PartnerLink = styled.a`
    font-family: "Graphik";
    color: #676777;
`;

const PartnerTitle = styled.p`
    margin: 0 0 12px 0;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #676777;

    ${isMobileMediaQuery} {
        padding: 0 20px;
    }
`;

const ProductDescription = styled.p`
    margin: 0 0 52px 0;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #676777;

    :first-of-type {
        text-transform: initial;
    }

    & > div {
        display: inline-block;
        margin-bottom: 0px;
    }

    strong {
        text-transform: initial;
        font-weight: 600;
        color: #34343b;

        ${isMobileMediaQuery} {
            text-transform: uppercase;
        }
    }
    a {
        color: #676777;
    }

    ${isMobileMediaQuery} {
        margin: 0px;

        & > div {
            display: block;
            margin-bottom: 8px;
            :not(:first-child) {
                margin-top: 16px;
            }
        }
    }
`;

const ShareRow = styled(Flex)`
    margin-bottom: 12px;
    font-family: "Graphik";
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.28px;
    text-align: left;
    color: #676777;
    box-sizing: border-box;

    img:first-child {
        margin-left: 10px;
    }
    img {
        height: 17px;
        margin-right: 4px;
        cursor: pointer;
    }
`;

const BreadCrumbItem = styled.div`
    font-family: "Graphik";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: 0.28px;
    text-align: left;
    color: #666679;
    text-decoration: none;

    :not(:last-child) {
        margin-right: 10px;
    }

    ${(props) =>
        props.active &&
        css`
            color: #fff;
        `};

    ${(props) =>
        (props.onClick || props.to || props.href) &&
        css`
            cursor: pointer;
        `}
`;

const ChevronRight = styled(Seta)`
    transform: scaleX(-1);
    :not(:last-child) {
        margin-right: 10px;
    }
`;

const NeverUsed = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    text-align: center;
    background-color: #7ca8af;
    position: absolute;
    top: 0px;
    right: 0px;
    color: #fff;
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    box-sizing: border-box;
`;

const SaleTag = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    text-align: center;
    background-color: #7ca8af;
    position: absolute;
    top: 0px;
    right: 0px;
    color: #fff;
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    padding-left: 16px;
    box-sizing: border-box;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 10px solid #fff;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }

    ${({ promo }) =>
        promo &&
        css`
            background-color: #e4858c;
            padding: 14px 37px;
            background-color: transparent;
            transform: rotate(-45deg);
            right: initial;
            left: -30px;

            span {
                z-index: 1;
            }
            &:after {
                z-index: 0;
                border-bottom: 50px solid #e4858c;
                border-left: 50px solid transparent;
                border-right: 50px solid transparent;
                border-top: 50px solid transparent;
            }
        `}
`;

const FreeShipping = styled.div`
    font-family: "Graphik";
    font-size: 11px;
    text-align: center;
    background-color: #f4ced1;
    color: #34343b;
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const FreeShippingSpan = styled.span`
    padding: 0px 1px;
`;
export default Product;
