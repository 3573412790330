import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { generateUUID } from "../Functions";
import api from "../../services/api";
import { Flex, isMobile, isMobileMediaQuery } from "../../helpers/styles";
import * as CartActions from "../../redux/actions/cart";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import Button from "../Button";
import MobileImagesCarroussel from "./MobileImagesCarroussel";
import ProductCarroussel from "../ProductCarroussel";
import SectionTitle from "../SectionTitle";
import LoadingSpinner from "../LoadingSpinner";

import ShareIcon from "./../../assets/images/share-icon.png";

const ProductModal = ({ show, product_slug, ...props }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const bodyElement = document.querySelector("body");
    const conservation_status = {
        brand_new: "Nunca usado",
        excelent: "Excelente",
        good: "Bom",
        used: "Usado",
    };
    const [currentProductSlug, setCurrentProductSlug] = useState(product_slug);
    const [modalActive, setModalActive] = useState(false);
    const [product, setProduct] = useState(undefined);
    const [currentImage, setCurrentImage] = useState(undefined);
    const user = useSelector((state) => state.user);
    const selectorProducts = useSelector((state) => state.cart.products) ?? [];

    useEffect(() => {
        return () => {
            const scrollY = bodyElement.style.top;

            bodyElement.style.position = "";
            bodyElement.style.top = "";
            bodyElement.style.left = "";
            bodyElement.style.right = "";

            window.scrollTo(0, -parseInt(scrollY || "0"));
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (product && product.related_items) {
            let featuredProductsDataLayer = [];

            product.related_items.map((item, index) =>
                featuredProductsDataLayer.push({
                    name: item.name,
                    id: item.id,
                    price: item.price_label,
                    brand: item.brand.name,
                    category: item.item_category.name,
                    position: index + 1,
                })
            );

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });

            // eslint-disable-next-line
            dataLayer.push({
                event: "productImpression",
                ecommerce: {
                    currencyCode: "BRL",
                    impressions: featuredProductsDataLayer,
                },
            });
        }
    }, [product]);

    useEffect(() => {
        setCurrentProductSlug(product_slug);

        // eslint-disable-next-line
    }, [product_slug]);

    useEffect(() => {
        show !== null && show !== undefined && show !== modalActive && setModalActive(show);

        // eslint-disable-next-line
    }, [show]);

    useEffect(() => {
        props.callback && props.callback(modalActive);

        if (modalActive) {
            loadProduct();
        } else {
            const searchParams = new URLSearchParams(location.search);
            searchParams.delete("product");

            navigate(location.pathname + "?" + searchParams.toString());
        }
    }, [modalActive, currentProductSlug, location.search, navigate]);

    useEffect(() => {
        product && product.item.images && product.item.images.length > 0 && setCurrentImage(product.item.images[0]);

        // eslint-disable-next-line
    }, [product]);

    useEffect(() => {
        if (modalActive) {
            let top = window.scrollY;

            bodyElement.style.position = "fixed";
            bodyElement.style.top = `-${top}px`;
            bodyElement.style.left = "0px";
            bodyElement.style.right = "0px";
        } else {
            const scrollY = bodyElement.style.top;

            bodyElement.style.position = "";
            bodyElement.style.top = "";
            bodyElement.style.left = "";
            bodyElement.style.right = "";

            window.scrollTo(0, -parseInt(scrollY || "0"));
        }

        // eslint-disable-next-line
    }, [modalActive]);

    const loadProduct = async () => {
        setProduct(undefined);

        if (!currentProductSlug) return;

        let response = await api.get(`/items/${currentProductSlug}`).catch((err) => err.response);

        if (response.status === 200) {
            // eslint-disable-next-line

            let userSessionServerData = window.userCurrentServerData(user);
            let event_id = generateUUID();
            let event_time = Math.round(Date.now() / 1000);

            window.fbq(
                "trackCustom",
                "ViewProduct",
                {
                    content_category: response.data.item.item_category.name || "",
                    content_name: response.data.item.name || "",
                    content_type: "product",
                    value: response.data.item.price_label || "",
                },
                { eventID: event_id }
            );

            await api
                .post("/facebook_ads_event", {
                    data: [
                        {
                            event_name: "ViewProduct",
                            event_id: event_id,
                            event_time: event_time,
                            event_source_url: window.location.href,
                            action_source: "website",
                            user_data: userSessionServerData,
                            custom_data: {
                                content_category: response.data.item.item_category.name || "",
                                content_name: response.data.item.name || "",
                                content_type: "product",
                                value: response.data.item.price_label || "",
                            },
                        },
                    ],
                })
                .then((res) => console.log("EVENTO ViewProduct", res.data))
                .catch((res) => res.response);

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({
                ecommerce: {
                    detail: {
                        products: [response.data],
                    },
                },
            });
            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({
                event: "virtualPageview",
                page: document.title,
                type: "ProductModal",
                environment: process.env.REACT_APP_ENV,
                user: [
                    {
                        name: user.first_name && user.last_name ? user.first_name + " " + user.last_name : "",
                        username: user.slug ? user.slug : "",
                        user_id: user.id ? user.id : "",
                        email: user.email ? user.email : "",
                        language: "pt-br",
                        returning: JSON.parse(localStorage.getItem("accepted_cookies")) ? true : false,
                    },
                ],
            });

            setCurrentImage(response.data.item.images[0]);
            setProduct({ ...response.data });
        } else {
            setModalActive(false);
        }
    };

    const handleClose = () => {
        setProduct(undefined);
        setCurrentImage(undefined);
        setModalActive(false);
    };

    const handleAddToCart = () => {
        dispatch(CartActions.addProduct(product.item));

        if (user.token) {
            selectorProducts.push(product.item);
            saveAbandonedCart(selectorProducts.map((product) => product.id));
        }

        // eslint-disable-next-line
        let userSessionServerData = window.userCurrentServerData(user);
        let event_id = generateUUID();
        let event_time = Math.round(Date.now() / 1000);

        window.fbq(
            "track",
            "AddToCart",
            {
                contents: product,
                value: product.item.price_label,
                currency: "BRL",
            },
            { eventID: event_id }
        );

        api.post("/facebook_ads_event", {
            data: [
                {
                    event_name: "AddToCart",
                    event_id: event_id,
                    event_time: event_time,
                    event_source_url: window.location.href,
                    action_source: "website",
                    user_data: userSessionServerData,
                    custom_data: {
                        contents: product,
                        value: product.item.price_label,
                        currency: "BRL",
                    },
                },
            ],
        })
            .then((res) => console.log("EVENTO AddToCart", res.data))
            .catch((res) => res.response);

        // eslint-disable-next-line
        dataLayer.push({ ecommerce: null });
        // eslint-disable-next-line
        dataLayer.push({
            event: "addToCart",
            ecommerce: {
                currencyCode: "BRL",
                add: {
                    products: [
                        {
                            name: product.item.name,
                            id: product.item.id,
                            price: product.item.price_label,
                            brand: product.item.brand.name,
                            category: product.item.item_category.name,
                            quantity: 1,
                        },
                    ],
                },
            },
        });

        handleClose();
    };

    const saveAbandonedCart = async (idsProducts) => {
        try {
            let response = await api.post("/carts", {
                item_ids: idsProducts,
            });
            if (response.status === 204) {
                console.log("saveAbandonedCart: /carts");
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const handleMousePan = (e) => {
        var bounds = e.target.getBoundingClientRect();
        var x = e.clientX - bounds.left;
        var y = e.clientY - bounds.top;

        let img = e.target.childNodes[0];

        img.style.position = "absolute";

        let xPercent = x / (img.width / 100);
        let yPercent = y / (img.height / 100);

        img.style.transform = `scale(2) translate(${(50 - xPercent) / 1.5}%, ${(50 - yPercent) / 1.5}%)`;

        if (img.src.indexOf("?") > 0)
            img.src = img.src.substring(0, img.src.indexOf("?")) + "?w=1000&bg=FFFFFF&auto=format,compress";
    };

    const handleResetZoom = (e) => {
        let img = e.target.childNodes[0];

        img.style.transform = "";
        img.src =
            img.src.substring(0, img.src.indexOf("?") > 0 ? img.src.indexOf("?") : img.src.length) +
            "?w=361&h=397&bg=FFFFFF&auto=format,compress";
    };

    return (
        modalActive && (
            <Container>
                <Backdrop onClick={handleClose} />
                <Modal>
                    <CloseButton onClick={handleClose}>
                        <CloseIcon />
                    </CloseButton>

                    {!product && (
                        <Flex minHeight="30vh">
                            <LoadingSpinner dark size={36} />
                        </Flex>
                    )}

                    {product && (
                        <>
                            <Flex flex="1" width="100%" direction={isMobile ? "column" : "row"}>
                                <Flex direction="column" margin="auto 20px auto 0" desktop>
                                    {product.item.images.map((image, index) => (
                                        <PreviewBox
                                            key={`image_${index}`}
                                            onMouseEnter={() => setCurrentImage(image)}
                                            image={image}
                                            active={currentImage === image}
                                        />
                                    ))}
                                </Flex>

                                <Flex direction="column" mobile>
                                    <ProductTitle>{product.item.name}</ProductTitle>
                                    <ProductPrice>Cod.: {product.item.id.toString()}</ProductPrice>

                                    <ProductPrice oldPrice={product.item.original_price_label}>
                                        R${" "}
                                        {(!!product.item.original_price_label
                                            ? product.item.original_price_label || product.item.price_label
                                            : product.item.price_label
                                        ).toLocaleString("pt-br", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </ProductPrice>
                                    {!!product.item.original_price_label && (
                                        <ProductPrice promoPrice>
                                            R${" "}
                                            {product.item.price_label.toLocaleString("pt-br", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </ProductPrice>
                                    )}
                                </Flex>

                                <Flex
                                    width={isMobile ? "100%" : "auto"}
                                    height="100%"
                                    direction="column"
                                    margin={isMobile ? "0 auto 15px auto" : "auto 35px auto 0"}
                                >
                                    <Flex width="100%" flex="1" direction="column">
                                        <ImageBox
                                            onMouseMove={!isMobile && handleMousePan}
                                            onMouseLeave={!isMobile && handleResetZoom}
                                        >
                                            {!isMobile ? (
                                                <img src={currentImage} alt="Product Preview" />
                                            ) : (
                                                <MobileImagesCarroussel images={product.item.images} />
                                            )}
                                            {product.item.conservation === "used" && <NeverUsed>Usado</NeverUsed>}

                                            {product.item.original_price_label && (
                                                <NeverUsed promo>
                                                    <span>SALE</span>
                                                </NeverUsed>
                                            )}
                                        </ImageBox>

                                        {navigator && navigator.share !== undefined && (
                                            <ShareRow
                                                width={isMobile ? "100%" : "398px"}
                                                background="#F3F5F5"
                                                justifyContent="flex-start"
                                                padding="15px 0px 12px 21px"
                                            >
                                                Gostou? Compartilhe:
                                                <img
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        if (
                                                            navigator &&
                                                            navigator.share !== undefined &&
                                                            navigator.share
                                                        ) {
                                                            navigator
                                                                .share({
                                                                    title:
                                                                        product.item.item_category.name.toLowerCase() +
                                                                        " " +
                                                                        product.item.brand.name.toLowerCase(),

                                                                    url:
                                                                        window.location.origin +
                                                                        "/produto/" +
                                                                        product.item.slug,
                                                                })
                                                                .catch((error) => console.log("Error sharing", error));
                                                        } else {
                                                            console.log(
                                                                "Share not supported on this browser, do it the old way."
                                                            );
                                                        }
                                                    }}
                                                    height="29px"
                                                    src={ShareIcon}
                                                    alt="Compartilhar"
                                                />
                                            </ShareRow>
                                        )}
                                    </Flex>
                                </Flex>

                                <Flex
                                    height="100%"
                                    flex="1"
                                    direction="column"
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                    margin="auto 0 auto 0"
                                    maxWidth="355px"
                                >
                                    <Flex
                                        direction="column"
                                        alignItems="flex-start"
                                        justifyContent="flex-start"
                                        desktop
                                    >
                                        <ProductTitle>{product.item.name}</ProductTitle>
                                        <ProductPrice>Cod.: {product.item.id.toString()}</ProductPrice>

                                        <ProductPrice oldPrice={!!product.item.original_price_label}>
                                            R${" "}
                                            {(!!product.item.original_price_label
                                                ? product.item.original_price_label || product.item.price_label
                                                : product.item.price_label
                                            ).toLocaleString("pt-br", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </ProductPrice>
                                        {!!product.item.original_price_label && (
                                            <ProductPrice promoPrice>
                                                R${" "}
                                                {product.item.price_label.toLocaleString("pt-br", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </ProductPrice>
                                        )}
                                    </Flex>

                                    <Flex alignItems="flex-start" margin="0 0 20px 0">
                                        <ProductDescription>
                                            {product.item.size && (
                                                <>
                                                    <strong>Tamanho: </strong>
                                                    {product.item.size.toLowerCase()}
                                                    <br />
                                                </>
                                            )}
                                            {product.item.gender && (
                                                <>
                                                    <strong>Gênero:</strong> {product.item.gender === 'male' ? 'Masculino' : product.item.gender === 'female' ? 'Feminino' : 'Unissex'}
                                                    <br />
                                                </>
                                            )}
                                            <strong>Cor: </strong>
                                            {product.item.color_list &&
                                                product.item.color_list
                                                    .map((item) => item.name.toLowerCase())
                                                    .join(", ")}
                                            <br />
                                            {product.item.item_design && (
                                                <>
                                                    <strong>Estampa: </strong>
                                                    {product.item.item_design.name}
                                                    <br />
                                                </>
                                            )}
                                            {product.item.brand && (
                                                <>
                                                    <strong>Marca: </strong>
                                                    {product.item.brand.name.toLowerCase()}
                                                    <br />
                                                </>
                                            )}
                                            {product.item.item_fabric && (
                                                <>
                                                    <strong>Composição: </strong>
                                                    {product.item.item_fabric.name}
                                                    <br />
                                                </>
                                            )}
                                            {product.item.conservation && (
                                                <>
                                                    <strong>Estado de conservação: </strong>
                                                    {conservation_status[product.item.conservation]}
                                                    <br />
                                                </>
                                            )}
                                            {product.item.notes && (
                                                <>
                                                    <strong>Observações: </strong>
                                                    {product.item.notes}
                                                </>
                                            )}
                                        </ProductDescription>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        margin={isMobile ? "0 auto 15px auto" : "auto 0 17px 0"}
                                        onClick={handleAddToCart}
                                    >
                                        <Button
                                            width={isMobile ? "60%" : "65%"}
                                            dark
                                            filled
                                            borderradius
                                            value={isMobile ? "Adicionar" : "Colocar na Sacola"}
                                            margin="0"
                                        />
                                        <Button
                                            width={isMobile ? "40%" : "35%"}
                                            dark
                                            value={`R$ ${parseFloat(product.item.price_label).toLocaleString("pt-br", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}`}
                                            margin="0"
                                        />
                                    </Flex>

                                    <Flex alignItems="flex-start" margin="0 0 3px 0">
                                        <HintMessage>
                                            Entregamos para você em qualquer lugar do Brasil.
                                            <br />
                                            Pague em até 4 vezes.
                                        </HintMessage>
                                    </Flex>
                                </Flex>
                            </Flex>

                            {product.related_items && (
                                <>
                                    <SectionTitle margin={isMobile && "40px 0 0 0"}>
                                        Você também pode gostar:
                                    </SectionTitle>

                                    <ProductCarroussel
                                        items={product.related_items || []}
                                        margin={isMobile && "0 0 30px 0"}
                                        normalButtons
                                        locale={"Product_modal"}
                                    />
                                </>
                            )}
                        </>
                    )}
                </Modal>
            </Container>
        )
    );
};

const Container = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    overflow-y: auto;
    padding: 40px 0;

    ${isMobileMediaQuery} {
        padding: 90px 0px;
        align-items: baseline;
        max-height: 100vh;
        overflow-y: auto;
    }
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.7);
`;

const Modal = styled.div`
    position: relative;
    width: 1000px;
    height: auto;
    max-width: 90vw;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 0 rgba(52, 52, 59, 0.15);
    padding: 60px 72px 60px 50px;
    margin: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    box-sizing: border-box;

    ${isMobileMediaQuery} {
        padding: 38px 20px 28px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        max-width: 90vw;
        max-height: initial;
        height: auto;
    }
`;

const CloseButton = styled.button`
    padding: 0px;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0px;
    right: 0px;
    margin: 19px;
    border: 0px;
    background: transparent;
    cursor: pointer;
    z-index: 20;

    svg {
        transform: scale(0.8);
    }
`;

const ImageBox = styled.div`
    position: relative;
    border: solid 1px #f4f4f5;
    background-color: #ffffff;
    height: 397px;
    min-height: 397px;
    max-height: 397px;
    overflow: hidden;
    width: 398px;
    max-width: 398px;
    flex: 1;

    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        pointer-events: none;
    }

    ${isMobileMediaQuery} {
        width: 100%;
        max-width: 100%;
        height: auto;
        border: none;

        img {
            border: solid 1px #f4f4f5;
        }
    }
`;

const PreviewBox = styled.div`
    position: relative;
    border: solid 1px #f4f4f5;
    background-color: #ffffff;
    height: 70px;
    width: 70px;
    cursor: pointer;
    ${(props) => !props.active && `opacity: 0.7;`}

    background-image: url(${(props) => props.image});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    :hover {
        opacity: 1;
    }

    :not(:last-child) {
        margin-bottom: 10px;
    }
`;

const ProductTitle = styled.h1`
    margin: 0 0 19px 0;
    text-transform: capitalize;

    font-family: "DMSerifDisplay";
    font-size: 38px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.87;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        text-align: center;
        font-size: 24px;
        max-width: 80%;
    }
`;

const ProductPrice = styled.p`
    margin: 0 0 17px 0;
    font-family: "Graphik";
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #838383;

    ${isMobileMediaQuery} {
        font-size: 13px;
    }

    ${({ oldPrice }) =>
        oldPrice &&
        css`
            text-decoration: line-through;
            opacity: 0.9;
            margin-bottom: 8px;
            line-height: 1.25;
        `}

    ${({ promoPrice }) =>
        promoPrice &&
        css`
            font-size: 18px;
            color: #e4858c;

            ${isMobileMediaQuery} {
                font-size: 16px;
            }
        `}
`;

const HintMessage = styled.p`
    margin: 0px;
    font-family: "Graphik";
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    a {
        color: #34343b;
        text-decoration: underline;
    }
`;

const ProductDescription = styled.p`
    margin: 0 0 52px 0;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #676777;

    :first-of-type {
        text-transform: initial;
    }

    & > div {
        display: inline-block;
        margin-bottom: 0px;
    }

    strong {
        text-transform: initial;
        font-weight: 600;
        color: #34343b;

        ${isMobileMediaQuery} {
            text-transform: uppercase;
        }
    }
    a {
        color: #676777;
    }

    ${isMobileMediaQuery} {
        margin: 0px;

        & > div {
            display: block;
            margin-bottom: 8px;
            :not(:first-child) {
                margin-top: 16px;
            }
        }
    }
`;

const ShareRow = styled(Flex)`
    margin-bottom: 12px;
    font-family: "Graphik";
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.28px;
    text-align: left;
    color: #676777;
    box-sizing: border-box;

    img:first-child {
        margin-left: 10px;
    }
    img {
        height: 17px;
        margin-right: 4px;
        cursor: pointer;
    }
`;

const NeverUsed = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    text-align: center;
    background-color: #7ca8af;
    position: absolute;
    top: 16px;
    right: 0px;
    color: #fff;
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    padding-left: 16px;
    box-sizing: border-box;

    &:after {
        content: "";
        position: absolute;
        left: -1px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 10px solid #fff;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }

    ${({ promo }) =>
        promo &&
        css`
            top: 0px;
            background-color: #e4858c;
            padding: 14px 37px;
            background-color: transparent;
            transform: rotate(-45deg);
            right: initial;
            left: -30px;

            span {
                z-index: 1;
            }

            &:after {
                z-index: 0;
                border-bottom: 50px solid #e4858c;
                border-left: 50px solid transparent;
                border-right: 50px solid transparent;
                border-top: 50px solid transparent;
            }
        `}
`;

export default ProductModal;
